import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Rating } from 'types/enums/Rating';

import OrderWorkunit from 'types/entities/OrderWorkunit';
import { MessageType } from 'types/enums/MesageType';

import React, { useCallback, useState } from 'react';
import NotificationApiService from 'api/NotificationApiService';
import { appState } from 'store/states/appState';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import { useAppState } from 'store/Provider';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import { closeDialogAction } from 'store/actions/dialogsActions';

const DeliverableTableValidateButtonsDialog: React.FC = () => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const open = useSelector((state) => state.dialogs.deliverableDialogChangeAdvancement);

  const dialogState = useSelector((state) => state.dialogs);
  const {
    activeDeliverable = undefined,
    acceptState = undefined,
    accept = undefined,
    refuse = undefined,
    withReserve = undefined,
  } = dialogState?.data ?? {
    acceptState: undefined,
    accept: undefined,
    refuse: undefined,
    withReserve: undefined,
  };
  const [comment, setComment] = useState('');
  const [withReserveChecked, setWithReserveChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    setComment('');
    setWithReserveChecked(false);
    setLoading(false);
    dispatch(closeDialogAction('deliverableDialogChangeAdvancement'));
  };

  const { changeMissionAdvancement, setRatingDeliverables, addCommentDeliverable } = useDeliverableService();

  const validateDeliverable = async (missionToChange: OrderWorkunit, validation: number) => {
    await changeMissionAdvancement([missionToChange.id], validation);

    if (validation === MissionAdvancementPossibilities.REFUSED_CLIENT) {
      await setRatingDeliverables(
        [{ ...missionToChange, mission_advancement_id: MissionAdvancementPossibilities.REFUSED_CLIENT }],
        Rating.E,
        null
      );
    }
  };

  const sendNewComment = async (mission_advancement_id: number) => {
    const commentAdded = await addCommentDeliverable(activeDeliverable as OrderWorkunit, {
      comment,
      mission_advancement: mission_advancement_id,
      is_private: !(mission_advancement_id > MissionAdvancementPossibilities.ACCEPTATION_DC),
    });
    if (commentAdded) {
      await NotificationApiService.Instance.postNotification({
        type: MessageType.mission_just_confirmed,
        appendix: commentAdded.id,
        uri: `/${appState.customer?.slug}/deliverables/${activeDeliverable?.order_id}`,
        wording: (activeDeliverable?.workunit_reference as string) ?? (activeDeliverable?.workunit_name as string),
      });
    }

    return commentAdded;
  };
  const handleDialogCallbackCall = async () => {
    setLoading(true);
    if (acceptState && !withReserveChecked) {
      if (comment !== '') await sendNewComment(accept);
      if (typeof comment !== 'undefined') await validateDeliverable(activeDeliverable as OrderWorkunit, accept);
      else await validateDeliverable(activeDeliverable as OrderWorkunit, accept);
    } else if (withReserveChecked && acceptState) {
      await sendNewComment(withReserve[0]);

      await validateDeliverable(activeDeliverable as OrderWorkunit, withReserve[0]);
    } else if (!acceptState) {
      await sendNewComment(refuse);
      await validateDeliverable(activeDeliverable as OrderWorkunit, refuse);
    }
    handleCancel();
  };

  const displayAction = useCallback(() => {
    const submitText = acceptState ? t('Submit_accepted') : t('Submit_refusal');
    let tooltip = null;

    if (comment === '') {
      if ((acceptState && withReserveChecked) || !acceptState) {
        tooltip = (
          <Tooltip
            title={t<string>('Comment required')}
            componentsProps={{
              popper: {
                className: 'tooltip-dialog',
              },
            }}
          >
            <span>{submitText}</span>
          </Tooltip>
        );
      }
    }

    return tooltip || submitText;
  }, [acceptState, comment, t, withReserveChecked]);
  return (
    <Dialog open={open} fullWidth maxWidth="xs" id="dialog-confirm-validate">
      <DialogTitle>
        {t('Leave_a_comment')}
        {((acceptState && withReserveChecked) || !acceptState) && (
          <sup>
            <Typography color="error" display="inline">
              {' '}
              *
            </Typography>
          </sup>
        )}
      </DialogTitle>
      <DialogContent>
        <TextField
          value={comment || ''}
          onChange={(e) => setComment(e.currentTarget.value)}
          rows={5}
          fullWidth
          multiline
          sx={{ mb: 1 }}
        />
        {acceptState && (
          <FormControlLabel
            control={
              <Checkbox
                name={t('with_reserve')}
                value={withReserveChecked}
                onChange={(e) => setWithReserveChecked(e.target.checked)}
              />
            }
            label={t('Accept_with_reserve') as string}
          />
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton disabled={loading} loading={loading} autoFocus onClick={handleCancel}>
          Cancel
        </LoadingButton>

        <LoadingButton
          variant="contained"
          loading={loading}
          disabled={(comment === '' && ((acceptState && withReserveChecked) || !acceptState)) || loading}
          onClick={() => handleDialogCallbackCall()}
          color={acceptState ? 'success' : 'error'}
        >
          {displayAction()}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeliverableTableValidateButtonsDialog;
