import { Add, ArrowLeft, ArrowRight, Pentagon, PeopleAlt, RestartAlt, Save, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  MenuList,
  Menu,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CustomersApiService from 'api/CustomersApiService';
import RolesApiService from 'api/RolesApiService';
import TenantWorkloadApiService, { UpdateTenantWorkload } from 'api/TenantWorkloadApiService';
import UserApiService from 'api/UserApiService';
import { Layout, Loader } from 'components';
import CheckboxSelection from 'components/CheckboxSelection/CheckboxSelection';
import { stringToColor } from 'helpers/format';
import { sortedTenantWorkload } from 'helpers/utils';
import useApi from 'hooks/useApi';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Customer from 'types/entities/Customer';
import Role from 'types/entities/Role';
import User from 'types/entities/User';
import UserRole from 'types/entities/UserRole';
import AdminTenantSettingRow from '../AdminTenantSettings/AdminTenantSettingRow/AdminTenantSettingsRow';
import UserRoleCellAdmin from '../AdminUsersPermissions/UserRowAdmin/UserRowAdmin';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { loadCustomerAction, updateCustomerAction } from 'store/actions/appActions';
import { LoadingButton } from '@mui/lab';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DeleteIcon } from 'components/CustomIcons';
import ClientsDialogDeactivateUser from '../../Clients/ClientsDialogs/ClientsDialogDeactivateUser';
import { openDialogAction } from 'store/actions/dialogsActions';
import ClientDialogUnassignUser from '../../Clients/ClientsDialogs/ClientDialogUnassignUser';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <>
      {value === index && (
        <Box
          sx={{ flex: 1 }}
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export type TenantMonthsWorkload = {
  is_daily_unit: boolean;
  is_monthly: boolean;
  workload: number;
  tenant_id: number | undefined;
  date: string;
  id: number;
  total_workload?: number;
  created_at: string;
  updated_at: string;
};

const AdminDashboard: React.FC<RouteComponentProps> = ({ match }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { dispatch } = useAppState();

  const appState = useSelector((state) => state.app);
  const loadings = useSelector((state) => state.loadings);
  const [is_days] = useState(false);
  const { makeCall } = useApi();
  const [t] = useTranslation();
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, user: User) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };
  const handleDeactivateUser = (user: User) => {
    // logic to deactivate the user
    dispatch(openDialogAction({ name: 'clientDeactivateUser' }));
  };

  const handleUnassignUser = () => {
    // logic to unassign the user
    dispatch(openDialogAction({ name: 'ClientDialogUnassignUser' }));
  };

  const [year, setYear] = useState<{
    id?: number;
    is_daily_unit: boolean;
    is_monthly: boolean;
    workload: number;
    tenant_id: number | undefined;
    date: string;
  }>({
    is_daily_unit: true,
    is_monthly: false,
    workload: 12,
    tenant_id: appState.customer?.id ?? undefined,
    date: moment().startOf('year').format(),
  });
  const [currentYear, setCurrentYear] = useState<number>(moment().year());
  const [months, setMonths] = useState<TenantMonthsWorkload[]>(
    Array.from({ length: 12 }, (_, i) => ({
      is_daily_unit: true,
      is_monthly: true,
      workload: 0,
      tenant_id: appState.customer?.id ?? undefined,
      date: moment().startOf('year').add(i, 'month').format(),
      id: i,
      total_workload: 0,
      created_at: '',
      updated_at: '',
    }))
  );

  // eslint-disable-next-line no-template-curly-in-string
  useEffect(() => {
    dispatch(addLoadingAction('getTenantWorkloads'));
    const getTenantWorkloads = async () => {
      const yearsParams = `&date=${moment(new Date(`01/01/${currentYear}`))
        .format('YYYY-MM-DD')
        .toString()}&tenant_id=${appState.customer?.id}&is_monthly=0&join=tenant-workloads
        `;

      const res = await TenantWorkloadApiService.fetchAllWorkloads(yearsParams);
      if (res[0]) {
        setYear(res[0]);
      }
      if (res[0]?.['tenant-workloads'].length > 0) {
        setMonths(sortedTenantWorkload(res[0]['tenant-workloads']));
      } else {
        setMissionsMonths();
      }
      dispatch(removeLoadingAction('getTenantWorkloads'));
    };
    getTenantWorkloads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear]);

  const totalMonthEqualYearValue = () => {
    if (year.workload !== 0 && months) return months.reduce((a, b) => a + b.workload, 0) === year.workload;
    return false;
  };
  const [isTenantUpdateLoading, setIsTenantUpdateLoading] = useState(false);
  const updateTenantWorkload = async () => {
    setIsTenantUpdateLoading(true);
    try {
      if (totalMonthEqualYearValue()) {
        const monthsToSend = months.map(({ total_workload, ...m }) => m);
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          total_workload = 0,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          tenant_workload = [],
          ...yearToSend
        } = {
          ...year,
          date: moment(new Date(`01/01/${currentYear}`))
            .format('YYYY-MM-DD')
            .toString(),
        };
        const bodyUpdate: UpdateTenantWorkload = {
          ...yearToSend,
          total_workload: monthsToSend.reduce((a, b) => a + b.workload, 0),
          month_workloads: monthsToSend.map((m) => ({
            ...m,
            parent_id: year?.id,
            is_monthly: true,
            is_daily_unit: year?.is_daily_unit,
          })),
        } as unknown as UpdateTenantWorkload;
        const res = await makeCall(TenantWorkloadApiService.updateMany(bodyUpdate));
      }
      dispatch(
        setSnackbarAction({ message: 'Update tenant workload settings successfull', open: true, severity: 'success' })
      );
    } catch (error) {
      dispatch(
        setSnackbarAction({ message: 'Failed to update tenant workload settings', open: true, severity: 'error' })
      );
    } finally {
      setIsTenantUpdateLoading(false);
    }
  };

  async function setMissionsMonths() {
    const minDate = moment(`01-01-${currentYear}`, 'MM-DD-YYYY').startOf('year');
    const maxDate = moment(`01-01-${currentYear}`, 'MM-DD-YYYY').endOf('year');

    const interim = minDate.clone();
    const timeValues = [];

    while (maxDate >= interim) {
      timeValues.push({
        is_daily_unit: is_days,
        is_monthly: false,
        workload: parseFloat((12 / 12).toFixed(5)),
        tenant_id: appState.customer?.id,
        date: interim.endOf('month').format('MM-DD-YYYY'),
      });
      interim.add(1, 'month');
    }

    const res = await TenantWorkloadApiService.postTenantWorkload({
      ...{
        ...year,
        date: moment(new Date(`01/01/${currentYear}`))
          .format('YYYY-MM-DD')
          .toString(),
      },
      month_workloads: [...timeValues.map((m) => ({ ...m, is_monthly: true, is_daily_unit: year.is_daily_unit }))],
    });
    if (res.annual_workload) {
      setYear(res.annual_workload);
      setMonths(sortedTenantWorkload(res.months_workload));
    }
  }

  const [users, setUsers] = useState<User[]>([]);
  const [foundUsers, setFoundUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [roleSearch, setRoleSearched] = useState<Role | null>(null);
  const [openDialogAddUser, setOpenDialogAddUser] = React.useState(false);

  const handleSearchByRole = (event: any, value: Role) => {
    setRoleSearched(value);
  };

  const resetFilters = () => {
    setRoleSearched(null);
    setSearched('');
  };

  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  useEffect(() => {
    const getUsersInCustomer = async () => {
      dispatch(addLoadingAction('getUsersInCustomer'));
      const res = await makeCall(RolesApiService.fetchAll(), 'Unable to retrieve roles');
      // setRoles(res.filter((role: Role) => role.name !== 'customer'));
      setRoles(res.filter((role: Role) => role.name !== 'customer' && role.is_archived === false));

      const usersRes = await makeCall(
        CustomersApiService.fetchAllUsers({ size: '-1' }),
        'Unable to retrieve users on customer'
      );

      setUsers(usersRes.map((result: User) => ({ ...result, roles: result['user-roles'][0] })));
      setFoundUsers(usersRes.map((result: User) => ({ ...result, roles: result['user-roles'][0] })));

      if (usersRes.results) {
        setFoundUserLength(usersRes.results.length);
      }

      setPage(0);
      dispatch(removeLoadingAction('getUsersInCustomer'));
    };
    if (appState.customer?.id) getUsersInCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer?.id]);
  const [contacts, setContacts] = useState<User[]>([]);
  const [inputValueContact, setInputValueContact] = useState<string>('');

  const handleClickRole = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, user: User, value: Omit<UserRole, 'id'>) => {
    setUsers(users.map((u) => (u.id !== user.id ? u : { ...u, 'user-roles': [value] })));
    setFoundUsers(foundUsers.map((u) => (u.id !== user.id ? u : { ...u, 'user-roles': [value] })));
    validatePermissionsUser({ ...user, 'user-roles': [value] });
  };
  const [isContactLoading, setIsContactLoading] = useState<boolean>(false);

  const validatePermissionsUser = async (user: User) => {
    setIsLoadingButton(true);
    try {
      await UserApiService.updateUserRole(
        user.id,
        (roles.find((r) => r.name === user['user-roles'][0].role_name) as Role).id as number
      );
    } catch (error) {
      const result = (error as Error).message;
      if (result) {
        dispatch(
          setSnackbarAction({ message: `Unable to update user on customer: ${result}`, open: true, severity: 'error' })
        );
      } else {
        dispatch(
          setSnackbarAction({ message: `Unable to update user on customer : ${error}`, open: true, severity: 'error' })
        );
      }
    } finally {
      setIsLoadingButton(false);
    }
  };

  const handleChangeNewUser = (selected: string) => {
    setNewUser({ ...newUser, roles: { id: selected } as unknown as Role });
  };

  const [searched, setSearched] = useState<string>('');

  useEffect(() => {
    searchFunction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searched, roleSearch]);
  const [foundUsersLength, setFoundUserLength] = React.useState(0);

  useEffect(() => {
    setFoundUserLength(foundUsersLength);
  }, [foundUsers, foundUsersLength]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function searchFunction() {
    const searchParam = searched.toLowerCase();
    if (searched !== '' && !roleSearch) {
      setFoundUsers(users.filter((user) => searchByName(user)));
    } else if (roleSearch && roleSearch.name && searched === '') {
      setFoundUsers(users.filter((user) => searchByRole(user)));
    } else if (roleSearch && roleSearch.name && searched !== '') {
      setFoundUsers(users.filter((user) => searchByRole(user) && searchByName(user)));
    } else if (searched === '' && roleSearch === null) {
      setFoundUsers(users);
    }
    setPage(0);

    function searchByRole(user: User) {
      return user['user-roles'].some((role) => role.role_name === roleSearch?.name);
    }

    function searchByName(user: User): unknown {
      return user.first_name.toLowerCase().includes(searchParam) || user.last_name.toLowerCase().includes(searchParam);
    }
  }

  const handleCloseDialogAddUser = () => {
    setOpenDialogAddUser(false);
  };
  const handleOpenDialogAddUser = () => {
    setOpenDialogAddUser(true);
  };

  const saveNewUser = async () => {
    setIsLoadingButton(true);
    try {
      const res = await UserApiService.updateUserRole(newUser?.user_id as number, newUser?.roles?.id as number);
      if (res) {
        const userWasAlreadyAssignedToCustomer = users.find((user) => user.id === newUser?.user_id);
        if (userWasAlreadyAssignedToCustomer && newUser?.roles && newUser?.roles?.id)
          setUsers(
            users.map((user) =>
              user.id !== userWasAlreadyAssignedToCustomer.id
                ? user
                : { ...user, roles: newUser?.roles ? [newUser?.roles] : [] }
            )
          );
        if (userWasAlreadyAssignedToCustomer && !newUser?.roles && !newUser?.roles?.id)
          setUsers(users.filter((user) => user.id !== userWasAlreadyAssignedToCustomer.id));
        if (!userWasAlreadyAssignedToCustomer) {
          setUsers([...users, { ...(newUser?.user as User), roles: newUser?.roles ? [newUser?.roles] : [] }]);
        }
      }
    } catch (error) {
      const result = (error as Error).message;

      if (result) {
        dispatch(
          setSnackbarAction({ message: `Unable to update user on customer: ${result}`, open: true, severity: 'error' })
        );
      } else {
        dispatch(
          setSnackbarAction({ message: `Unable to update user on customer : ${error}`, open: true, severity: 'error' })
        );
      }
    } finally {
      setOpenDialogAddUser(false);
      setIsLoadingButton(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setIsContactLoading(true);
      setContacts([]);
      try {
        if (inputValueContact !== '' && inputValueContact.length > 1) {
          const res = await makeCall(
            UserApiService.get({
              'last_name,first_name': `:${inputValueContact}:`,
              is_archived: 0,
              size: -1,
              is_client: 0,
            })
          );

          setContacts(res.datas);
        }
      } catch (e) {
        const result = (e as Error).message;
        if (result) {
          dispatch(
            setSnackbarAction({ message: `Failed to retrieve users : ${result}`, open: true, severity: 'error' })
          );
        } else {
          dispatch(setSnackbarAction({ message: `Failed to retrieve users :${e}`, open: true, severity: 'error' }));
        }
      } finally {
        setIsContactLoading(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueContact]);

  function setNewUserSelected(
    newUser: { roles?: Role | undefined; user_id?: number | undefined; customer_id?: number | undefined } | undefined,
    value: User | null,
    reason: string
  ): void {
    if (reason === 'clear') return setNewUser(undefined);
    const userIsAlreadyAssignedToCustomer = users.find((user) => user.id === value?.id);
    if (userIsAlreadyAssignedToCustomer) {
      return setNewUser({
        user_id: userIsAlreadyAssignedToCustomer.id,
        roles: userIsAlreadyAssignedToCustomer.roles?.[0],
      });
    }
    return setNewUser({ ...newUser, user_id: value?.id as User['id'], user: value as User });
  }

  const [newUser, setNewUser] =
    useState<{ roles?: Role; user_id?: User['id']; customer_id?: Customer['id']; user?: User }>();

  const changeClientConnection = (clientConnection: boolean) => {
    if (appState.customer) {
      dispatch(updateCustomerAction({ ...appState.customer, client_connection: clientConnection }));
    }
  };

  const updateCustomer = async () => {
    const dataToUpdate: Partial<Customer> = {
      client_connection: appState.customer?.client_connection,
    };
    dispatch(addLoadingAction('updateCustomer'));
    const res = await makeCall(
      CustomersApiService.updateCustomer(appState.customer?.id as number, dataToUpdate),
      'Unable to save customer'
    );
    if (res) {
      localStorage.setItem('active_customer', JSON.stringify(appState.customer));
    }
    dispatch(removeLoadingAction('updateCustomer'));
  };

  return (
    <Layout name="Settings" path="/administration">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          /*           orientation={mediumViewport ? 'vertical' : 'horizontal'}
          variant="scrollable" */
          value={value}
          onChange={handleChange}
          /*           aria-label="Vertical tabs example" */
          sx={{
            pl: { xs: 0, md: 0 },
            pt: { xs: 0, md: 2 },
            pb: { xs: 0, md: 2 },
            pr: { xs: 0, md: 2 },
            marginBottom: { md: { xs: 0, md: '-30px' } },
            marginTop: { md: { xs: 0, md: '-30px' } },
            mb: { xs: 3, md: 0 },
            mt: { xs: 0, md: -3 },
          }}
          TabIndicatorProps={{
            style: { paddingLeft: 20, paddingRight: 20 },
          }}
        >
          <Tab label={t('Tenant')} {...a11yProps(0)} icon={<Pentagon />} iconPosition="start" />
          <Tab label={t('Permissions')} {...a11yProps(1)} icon={<PeopleAlt />} iconPosition="start" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Typography color="neutral.main" variant="h3" sx={{ fontWeight: 700, mb: 1 }}>
            {appState.customer?.name}
          </Typography>
          <LoadingButton
            variant="contained"
            color="success"
            sx={{ mt: 1, mb: 3 }}
            onClick={updateCustomer}
            loading={!!loadings.updateCustomer}
          >
            {t('Save_modifications')}
          </LoadingButton>
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Typography color="neutral" variant="h5" sx={{ fontWeight: 700, mb: 4 }}>
                {t('client_parameters')}
              </Typography>
              <Grid
                container
                sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' } }}
              >
                <Grid item sx={{ mb: { xs: 1, md: 0 }, flex: 1 }}>
                  <Typography color="neutral">{t('client_connection_platform')}</Typography>
                </Grid>
                <Grid item /* xs={12} */ sx={{ mr: { xs: 0, md: 7 } }}>
                  <Stack direction="row" gap={2}>
                    <Button
                      sx={{ minWidth: 100 }}
                      onClick={() => changeClientConnection(true)}
                      variant={appState.customer?.client_connection ? 'contained' : 'outlined'}
                      startIcon={appState.customer?.client_connection ? <CheckCircleIcon /> : null}
                    >
                      {t('yes')}
                    </Button>
                    <Button
                      sx={{ minWidth: 100 }}
                      onClick={() => changeClientConnection(false)}
                      variant={!appState.customer?.client_connection ? 'contained' : 'outlined'}
                      startIcon={!appState.customer?.client_connection ? <CheckCircleIcon /> : null}
                    >
                      {t('no')}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <CheckboxSelection
                onChange={(e) => setYear({ ...year, is_daily_unit: e.target.checked })}
                label={t('Daily')}
                checked={year.is_daily_unit}
              />
              <Table sx={{ mb: 3 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <IconButton
                        onClick={() => setCurrentYear(currentYear - 1)}
                        size="small"
                        color="primary"
                        sx={{ mr: 1 }}
                      >
                        <ArrowLeft />
                      </IconButton>
                      {currentYear}
                      <IconButton
                        onClick={() => setCurrentYear(currentYear + 1)}
                        size="small"
                        color="primary"
                        sx={{ ml: 1 }}
                      >
                        <ArrowRight />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        label="total"
                        dir="rtl"
                        value={year.workload}
                        size="small"
                        onChange={(e) => setYear({ ...year, workload: parseFloat(e.target.value) })}
                      />{' '}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loadings.getTenantWorkloads &&
                    months &&
                    months?.map((m) => (
                      <AdminTenantSettingRow
                        is_days={year.is_daily_unit}
                        m={m}
                        setMonthValue={(workload: number, id: number, month: string) =>
                          setMonths(months.map((date) => (date.id !== id ? date : { ...date, workload })))
                        }
                      />
                    ))}
                </TableBody>
              </Table>
              <Box sx={{ display: 'flex' }}>
                <Button
                  onClick={() => updateTenantWorkload()}
                  disabled={!totalMonthEqualYearValue() || isTenantUpdateLoading}
                  startIcon={<Save />}
                  variant="contained"
                  sx={{ ml: 'auto' }}
                >
                  {isTenantUpdateLoading && <Loader size={10} />}
                  Save
                </Button>
              </Box>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography color="neutral.main" variant="h3" sx={{ fontWeight: 700, mb: 4 }}>
            Permissions
          </Typography>
          <Card>
            <CardContent>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button variant="contained" onClick={handleOpenDialogAddUser} startIcon={<Add />}>
                    {t('Assign user')}
                  </Button>
                  <Dialog open={openDialogAddUser} onClose={handleCloseDialogAddUser} maxWidth="xs" fullWidth>
                    <DialogTitle>{t('New_user')}</DialogTitle>
                    <DialogContent>
                      <Autocomplete
                        id="contact-autocomplete"
                        options={contacts}
                        autoHighlight
                        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                        renderOption={(boxprops, option, { selected }) => (
                          <Box component="li" {...boxprops}>
                            {option?.avatar_uri ? (
                              <Avatar src={option.avatar_uri} alt={t('Active_user_avatar')} sx={{ mr: 1 }} />
                            ) : (
                              <Avatar
                                sx={{
                                  backgroundColor: `${stringToColor(option?.first_name)}`,
                                  color: `${stringToColor(option?.first_name, 'color')}`,
                                  mr: 1,
                                }}
                              >{`${option?.first_name.charAt(0)}${option?.last_name.charAt(0)}`}</Avatar>
                            )}
                            {option.last_name} {option.first_name}
                          </Box>
                        )}
                        inputValue={inputValueContact}
                        onInputChange={(event, valueContact) => {
                          setInputValueContact(valueContact);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e, value, reason) => setNewUserSelected(newUser, value as User, reason)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('User')}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                        noOptionsText={
                          inputValueContact.length > 1 && contacts.length === 0
                            ? t('No contacts found')
                            : t('2_characters_Minimun')
                        }
                        loading={!contacts.length && isContactLoading}
                        size="small"
                        sx={{ mb: 3 }}
                      />

                      <FormControl fullWidth>
                        <InputLabel id="user-role-select-label">Role</InputLabel>
                        <Select
                          labelId="user-role-select-label"
                          id="user-role-select"
                          label="Role"
                          onChange={(e: SelectChangeEvent) => handleChangeNewUser(e.target.value)}
                        >
                          {roles.map((role) => (
                            <MenuItem value={role.id}>{t(role.name) as string}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex' }}>
                      <Button onClick={saveNewUser} startIcon={<Save />} variant="contained" sx={{ ml: 'auto' }}>
                        {!isLoadingButton ? t('Save_user') : <CircularProgress size="1rem" color="inherit" />}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                  <TextField
                    placeholder={t('Search')}
                    variant="outlined"
                    InputProps={{
                      endAdornment: <Search sx={{ color: 'neutral.main' }} />,
                    }}
                    size="small"
                    value={searched}
                    onChange={(e) => setSearched(e.currentTarget.value)}
                    sx={{ mr: 1 }}
                  />
                  <FormControl sx={{ mr: 1 }}>
                    <Select
                      labelId="filter-roles-select-label"
                      id="filter-roles-select"
                      value={roleSearch ? roleSearch.id : ''}
                      displayEmpty
                      sx={{ '& .MuiInputBase-input': { py: '8.5px' } }}
                    >
                      <MenuItem disabled value="">
                        Search by role
                      </MenuItem>
                      {roles.map((role) => (
                        <MenuItem value={role.id} onClick={(e) => handleSearchByRole(e, role)}>
                          {t(role?.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    onClick={(e) => resetFilters()}
                    startIcon={<RestartAlt />}
                    disabled={!roleSearch && !searched}
                  >
                    Reset filters
                  </Button>
                </Grid>
              </Grid>
              {!loadings.getUsersInCustomer && (
                <Table>
                  <TableBody>
                    {foundUsers
                      .sort((a, b) => a.last_name.localeCompare(b.last_name))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((user) => {
                        if (!user.is_client && !user.deactivation_date) {
                          return (
                            <TableRow key={user?.id}>
                              <TableCell component="td">
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  {user?.avatar_uri ? (
                                    <Avatar src={user.avatar_uri} alt={t('Active_user_avatar')} sx={{ mr: 1 }} />
                                  ) : (
                                    <Avatar
                                      sx={{
                                        backgroundColor: `${stringToColor(user?.first_name)}`,
                                        color: `${stringToColor(user?.first_name, 'color')}`,
                                        mr: 1,
                                      }}
                                    >{`${user?.first_name.charAt(0)}${user?.last_name.charAt(0)}`}</Avatar>
                                  )}
                                  <Typography>{`${user.last_name} ${user.first_name}`}</Typography>
                                </Box>
                              </TableCell>

                              <UserRoleCellAdmin
                                user={user}
                                users={users}
                                roles={roles}
                                handleClickRole={handleClickRole}
                                setUsers={setUsers}
                                /* validatePermissionsUser={validatePermissionsUser} */
                              />

                              <TableCell>
                                <IconButton
                                  aria-controls={`menu-${user.id}`}
                                  aria-haspopup="true"
                                  onClick={(event) => handleMenuOpen(event, user)}
                                >
                                  <MoreVertIcon />
                                </IconButton>

                                <Menu
                                  id={`menu-${user.id}`}
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl) && selectedUser === user}
                                  onClose={handleMenuClose}
                                >
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => handleUnassignUser()}
                                      key={`menuitem_unassign_user${Math.random() * 1100000}`}
                                      data-testid="unassign_user"
                                    >
                                      <ListItemIcon>
                                        <DeleteIcon fontSize="small" sx={{ color: 'red' }} />
                                      </ListItemIcon>
                                      <ListItemText sx={{ color: 'red' }}>{t('unassign_user')}</ListItemText>
                                    </MenuItem>
                                  </MenuList>
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => handleDeactivateUser(user)}
                                      key={`menuitem_deactivate_user${Math.random() * 1100000}`}
                                      data-testid="deactivate_user"
                                    >
                                      <ListItemIcon>
                                        <DeleteIcon fontSize="small" sx={{ color: 'red' }} />
                                      </ListItemIcon>
                                      <ListItemText sx={{ color: 'red' }}>{t('deactivate')}</ListItemText>
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          );
                        }
                        return null;
                      })}
                    <TablePagination
                      component={TableRow}
                      count={foundUsers.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableBody>
                </Table>
              )}
            </CardContent>
          </Card>
        </TabPanel>
      </Box>
      {selectedUser && <ClientDialogUnassignUser activeUser={selectedUser} setClients={setFoundUsers} />}
      {selectedUser && <ClientsDialogDeactivateUser activeUser={selectedUser} setClients={setFoundUsers} />}
    </Layout>
  );
};
export default AdminDashboard;
