import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { WarningIcon } from 'components/CustomIcons';

import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { selectActiveDeliverable } from 'store/selectors/deliverableSelectors';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import './DeliverableDialogCancelAcceptance.scss';

const DeliverableDialogCancelAcceptance = () => {
  const [t] = useTranslation();

  const { dispatch } = useAppState();
  const dialogs = useSelector((state) => state.dialogs);
  const loadings = useSelector((state) => state.loadings);
  const activeDeliverable = useSelector(selectActiveDeliverable);
  const { changeMissionAdvancement, handleDeliverableCancelComment, handleDeliverableCancelRating } =
    useDeliverableService();

  const handleConfirmClick = async () => {
    if (activeDeliverable) {
      dispatch(addLoadingAction('cancelAcceptance'));
      await Promise.allSettled([
        handleDeliverableCancelRating(activeDeliverable),
        handleDeliverableCancelComment(activeDeliverable),
        changeMissionAdvancement([activeDeliverable.id], MissionAdvancementPossibilities.ACCEPTATION_DC),
      ]);

      dispatch(removeLoadingAction('cancelAcceptance'));
    }
    handleClose();
  };

  const handleClose = () => {
    dispatch(closeDialogAction('deliverableCancelAcceptance'));
  };

  return (
    <Dialog maxWidth="sm" id="modal-cancel-acceptance" open={dialogs?.deliverableCancelAcceptance ?? false}>
      <DialogTitle>{t('Cancel acceptance')} ?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to confirm the acceptance&apos;s cancellation of this deliverable ?
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <DialogContentText className="warning">
          <span className="warning-content">
            <WarningIcon /> Warning: associated comment and assessment will be deleted
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          onClick={handleConfirmClick}
          loading={!!loadings.cancelAcceptance}
        >
          {t('Confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default DeliverableDialogCancelAcceptance;
