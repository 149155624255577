/* eslint-disable react-hooks/exhaustive-deps */
import { Add, Close, RemoveRedEye, Search } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Drawer,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { SearchParams } from 'api/ResourceAPI';
import WorkunitApiService from 'api/WorkunitApiService';
import { EditIcon } from 'components/CustomIcons';
import EmptyState from 'components/EmptyState';
import { Loader } from 'components/index';
import useUserRoles from 'hooks/useUserRoles';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { loadWorkunitAction } from 'store/actions/appActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { CatalogWorkunit } from 'types/entities/CatalogWorkunit';
import { RoleType } from 'types/RoleType';
import WorkunitCreation from './WorkunitCreation';
import WorkunitEdit from './WorkunitEdit';

const WorkUnitsList: React.FC = () => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const [workunits, setWorkunits] = useState<CatalogWorkunit[] | null>(null);
  const [open, dispatchOpen] = useReducer((prev) => !prev, false);
  const [loading, setLoading] = React.useState(false);
  const [searched, setSearched] = useState<string>('');

  type Params = {
    catalogId: string;
  };

  const { catalogId } = useParams<Params>();

  //  Fetch catalog's workunits and set it in context
  const queryConstructor = (searchString: string, page: number, rowsPerPage: number) => {
    const searchQuery: SearchParams = {
      page,
      size: rowsPerPage,
      join: ['devise', 'complexity'],
    };
    if (searchString !== '') searchQuery['reference,name'] = `:${searchString}:`;
    return searchQuery;
  };
  const [totalItems, setTotalItems] = useState(0);

  const fetchWorkunitsByCatalog = async () => {
    try {
      setLoading(true);
      const constructQuery = queryConstructor(searched, page, rowsPerPage);
      const res = await WorkunitApiService.get(Number(catalogId), constructQuery);
      setWorkunits(() => res.datas);
      setTotalItems(() => res.totalItems);
    } catch (e) {
      const result = (e as Error).message;
      if (result) {
        dispatch(
          setSnackbarAction({
            message: `Fetch workunits from active catalog failed  : ${result}`,
            open: true,
            severity: 'error',
          })
        );
      } else
        dispatch(
          setSnackbarAction({ message: 'Fetch workunits from active catalog failed ', open: true, severity: 'error' })
        );
    } finally {
      setLoading(false);
    }
  };

  const createWorkunitButtonClicked = () => {
    dispatchOpen();
    dispatch(loadWorkunitAction());
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // BUG: on first load from direct url, workunits will appear when search is triggered
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => fetchWorkunitsByCatalog(), 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searched, page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line consistent-return

  const upsertWorkunit = async (workunit: CatalogWorkunit) => {
    try {
      setLoading(true);

      setWorkunits(() => workunits?.map((wu) => (wu.id !== workunit.id ? wu : workunit)) || []);
    } catch (e) {
      const result = (e as Error).message;
      if (result) {
        dispatch(
          setSnackbarAction({
            message: `Fetch workunit from catalog failed  : ${result}`,
            open: true,
            severity: 'error',
          })
        );
      } else dispatch(setSnackbarAction({ message: 'Fetch workunit from catalog ', open: true, severity: 'error' }));
    } finally {
      setLoading(false);
      dispatchOpen();
    }
  };

  const [t] = useTranslation();
  return (
    <>
      <Grid container sx={{ mb: 5 }} spacing={4}>
        <Grid item xs={12} sm={6}>
          {userRoles.isAdmin && (
            <Button
              onClick={() => createWorkunitButtonClicked()}
              startIcon={<Add />}
              variant="contained"
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
              {t('Create_Workunit')}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'end' }}>
          <TextField
            placeholder={t('Search')}
            variant="standard"
            InputProps={{
              endAdornment: <Search />,
            }}
            value={searched}
            onChange={(e) => setSearched(e.currentTarget.value)}
            sx={{ ml: 'auto', width: { xs: '100%', sm: 'auto' } }}
          />
        </Grid>
      </Grid>

      {appState.workunit && appState.workunit.scope && (
        <>
          <div>{appState.workunit.scope.name}</div>
          <Close />
        </>
      )}
      {(loading || !workunits) && <Loader />}
      {!loading && workunits && workunits?.length === 0 && <EmptyState title="No Workunits in this catalog" />}
      {!loading && workunits && workunits.length > 0 && (
        <Card>
          <CardContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Ref')}</TableCell>
                    <TableCell>{t('Workunit')}</TableCell>
                    <TableCell>{t('Complexity')}</TableCell>
                    <TableCell>{t('Charge')}</TableCell>
                    <TableCell>{t('Price')}</TableCell>
                    <TableCell>{t('Devise')}</TableCell>
                    <TableCell align="right">{t('Actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workunits.map((row, index) => (
                    <RowCustom
                      row={row}
                      dispatchOpen={dispatchOpen}
                      key={`row_custom_${String(index)}`}
                      roles={appState.roles}
                    />
                  ))}
                </TableBody>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={totalItems}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      )}

      <Drawer
        anchor="right"
        ModalProps={{
          container: document.getElementById('container-content'),
        }}
        open={open}
        onClose={() => dispatchOpen()}
        PaperProps={{
          sx: {
            width: {
              xs: '100%',
              lg: 600,
            },
          },
        }}
      >
        {appState.workunit ? (
          <WorkunitEdit
            workunit={appState.workunit}
            catalog_id={Number(catalogId)}
            upsertWorkunit={upsertWorkunit}
            dispatchOpen={dispatchOpen}
          />
        ) : (
          <WorkunitCreation
            catalog_id={Number(catalogId)}
            catalogDevises={appState.devises || []}
            dispatchOpen={dispatchOpen}
            createWorkunit={(newWorkunit: CatalogWorkunit) => {
              setWorkunits((prevWorkunits) => [...(prevWorkunits || []), newWorkunit] || [newWorkunit] || []);
            }}
          />
        )}
      </Drawer>
    </>
  );
};

export default WorkUnitsList;

// Separate Row to isolate state
interface PropsRow {
  row: CatalogWorkunit;
  dispatchOpen: React.DispatchWithoutAction;
  roles: RoleType[] | undefined;
}

const RowCustom: React.FC<PropsRow> = ({ row, dispatchOpen, roles }) => {
  const { dispatch } = useAppState();

  const handleClick = () => {
    dispatch(loadWorkunitAction(row));
    if (dispatchOpen) {
      dispatchOpen();
    }
  };

  return (
    <TableRow key={row.id}>
      <TableCell>{row.reference}</TableCell>
      {/* <TableCell align="left" onClick={() => handleClick()} disabled={userRoles.isBu_support}> */}
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.complexity ? row.complexity.name : ''}</TableCell>
      <TableCell>{row.charge}</TableCell>
      <TableCell>{row.price.toFixed(2) || 0}</TableCell>
      <TableCell>
        {row?.devise?.symbol && row?.devise?.symbol !== 'E' ? row?.devise?.symbol?.toLowerCase() : '€'}
      </TableCell>

      <TableCell align="right">
        <IconButton color="primary" onClick={() => handleClick()}>
          {roles && roles.find((role) => role === 'admin') ? <EditIcon fontSize="small" /> : <RemoveRedEye />}
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
