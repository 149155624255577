import { PERMISSIONS } from 'helpers/permissions';
import useUserPermissions, { hasPermissions } from 'hooks/useUserPermissions';
import Order from 'types/entities/Order';
import { OrderStatus } from 'types/enums/OrderStatus';
import useUserRoles from '../useUserRoles';
import { OrderType } from 'types/enums/OrderType';

/**
 * React hook that defines rights specific to the Management feature
 */
export default function useManagementRights(order?: Order) {
  const userPermissions = useUserPermissions();
  const userRoles = useUserRoles();
  const rights = {
    canCreateOrder: hasPermissions([PERMISSIONS.CREATE_ORDER], userPermissions),
    canEditOrder: false,
    canCreateWorkunitOrder: false,
    canShowSummaryOrder: false,
    canAffectationOrder: false,
    canDuplicateOrder: hasPermissions([PERMISSIONS.DUPLICATE_ORDER], userPermissions),
    canDownloadOrder: false,
    canCloseOrder: false,
    canOpenOrder: false,
    canArchiveOrder: false,
    canRestoreOrder: false,
    canShowStatOrder: hasPermissions([PERMISSIONS.SHOW_STAT_ORDER], userPermissions),
    canShowGraphOrder: hasPermissions([PERMISSIONS.SHOW_GRAPH_ORDER], userPermissions),
    canShowKpiOrder:
      hasPermissions([PERMISSIONS.SHOW_KPI_ORDER], userPermissions) || userRoles.isCustomer || userRoles.isConsultant,
    canShowTabsOrder: hasPermissions([PERMISSIONS.SHOW_TABS_ORDER], userPermissions) || userRoles.isClient,
  };

  if (order) {
    if (rights.canDuplicateOrder) {
      if (userRoles.isDeliveryCoordinator && order.order_type_id === 1) {
        rights.canDuplicateOrder = false;
      }
    }

    rights.canEditOrder =
      hasPermissions([PERMISSIONS.EDIT_ORDER], userPermissions) &&
      [
        OrderStatus.DRAFTED,
        OrderStatus.PENDING,
        OrderStatus.VALIDATED,
        OrderStatus.PRODUCTION,
        OrderStatus.MATERIAL_DRAFT,
        OrderStatus.MATERIAL_PRODUCTION,
      ].includes(order.status) &&
      !order?.is_archived;
    rights.canCreateWorkunitOrder =
      hasPermissions([PERMISSIONS.CREATE_WORKUNIT_ORDER], userPermissions) &&
      [OrderStatus.DRAFTED, OrderStatus.PENDING].includes(order.status);
    rights.canShowSummaryOrder =
      hasPermissions([PERMISSIONS.SHOW_SUMMARY_ORDER], userPermissions) &&
      [OrderStatus.VALIDATED, OrderStatus.PRODUCTION].includes(order.status) &&
      !order?.is_archived;
    rights.canAffectationOrder =
      hasPermissions([PERMISSIONS.AFFECTATION_ORDER], userPermissions) &&
      [OrderStatus.VALIDATED, OrderStatus.PRODUCTION].includes(order.status) &&
      !order?.is_archived;
    rights.canDownloadOrder =
      order.order_type_id === OrderType.CATALOG &&
      hasPermissions([PERMISSIONS.DOWNLOAD_ORDER], userPermissions) &&
      order.price !== 0;
    rights.canCloseOrder =
      hasPermissions([PERMISSIONS.CLOSE_ORDER], userPermissions) &&
      (order.status === OrderStatus.PRODUCTION || order.status === OrderStatus.MATERIAL_PRODUCTION) &&
      !order.is_archived;
    rights.canOpenOrder =
      ((hasPermissions([PERMISSIONS.OPEN_ORDER], userPermissions) && order.status === OrderStatus.CLOSED) ||
        order.status === OrderStatus.MATERIAL_CLOSED) &&
      !userRoles.isConsultant &&
      !userRoles.isClient &&
      !userRoles.isBu_support;
    const canArchiveOrderQuote =
      hasPermissions([PERMISSIONS.ARCHIVE_ORDER_QUOTE], userPermissions) &&
      [OrderStatus.DRAFTED, OrderStatus.PENDING, OrderStatus.MATERIAL_DRAFT].includes(order.status);
    const canArchiveOrderManagement =
      hasPermissions([PERMISSIONS.ARCHIVE_ORDER_MANAGEMENT], userPermissions) &&
      [
        OrderStatus.VALIDATED,
        OrderStatus.PRODUCTION,
        OrderStatus.MATERIAL_DRAFT,
        OrderStatus.MATERIAL_PRODUCTION,
      ].includes(order.status);
    rights.canArchiveOrder =
      (canArchiveOrderQuote || canArchiveOrderManagement) && !order.is_archived && !userRoles.isDeliveryCoordinator;
    const canRestoreOrderQuote =
      hasPermissions([PERMISSIONS.RESTORE_ORDER_QUOTE], userPermissions) &&
      [OrderStatus.DRAFTED, OrderStatus.PENDING].includes(order.status);
    const canRestoreOrderManagement =
      hasPermissions([PERMISSIONS.RESTORE_ORDER_MANAGEMENT], userPermissions) &&
      [
        OrderStatus.VALIDATED,
        OrderStatus.PRODUCTION,
        OrderStatus.MATERIAL_DRAFT,
        OrderStatus.MATERIAL_PRODUCTION,
      ].includes(order.status);
    rights.canRestoreOrder = (canRestoreOrderQuote || canRestoreOrderManagement) && order.is_archived;
  }

  return rights;
}
