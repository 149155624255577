// import AvatarCustom from 'components/AvatarCustom/AvatarCustom';
import { Download } from '@mui/icons-material';
import { Box, Card, CardContent, IconButton, Link, Tooltip, Typography } from '@mui/material';
import AvatarCustom from 'components/AvatarCustom/AvatarCustom';
import StatusReport from 'components/StatusReport/StatusReport';
import { formatDateToddMMYYY, formatDateToMMMMYYYY } from 'helpers/format';
import useUserRoles from 'hooks/useUserRoles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'store/hooks';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import FinancialReport from 'types/entities/FinancialReport';
import { DeliverableReportTab } from 'types/enums/DeliverableReportTab';
import { ReportStatus } from 'types/enums/ReportStatus';
import signedDM from 'assets/img/icons/dm_signed.png';
import DeliverableReportMenu from './DeliverableReportMenu/DeliverableReportMenu';
import { useTranslation } from 'react-i18next';

type DeliverableReportsItemProps = {
  sheet: DeliverableSheet | FinancialReport;
  order_id: number;
};

function DeliverableReportsItem(props: DeliverableReportsItemProps) {
  const { sheet, order_id } = props;
  const { t } = useTranslation();
  const appState = useSelector((state) => state.app);
  const currentReportTab = useSelector((state) => state.deliverableSheet.currentReportTab);
  const userRoles = useUserRoles();
  const [pdfUrl] = React.useState<string | null>(null);
  const { id, title, created_at, start_date, end_date, client, state, signed_by_DM } = sheet;
  const constructTitleWithDateString = () => {
    const startDateString = formatDateToMMMMYYYY(start_date);
    const titleUppercase = title.toLocaleUpperCase();
    if (end_date === start_date) {
      return `${titleUppercase} - ${startDateString} `;
    }
    const endDateString = formatDateToMMMMYYYY(end_date);

    return `${titleUppercase} - ${startDateString} to ${endDateString}`;
  };
  const titleWithDateString = constructTitleWithDateString();
  const deviseName = (sheet as FinancialReport)?.devise_name;
  let filteredArray;
  if (deviseName !== null && typeof deviseName === 'object') {
    // Convert object to array
    const deviseArray = Object.values(deviseName);
    filteredArray = deviseArray.filter((value) => value !== null);
  }
  const devise_name = filteredArray && filteredArray[0];

  let devise_symbol;
  if (devise_name === 'Dollar' || devise_name === 'Canadian Dollar') {
    devise_symbol = '$';
  } else if (devise_name === 'Euro') {
    devise_symbol = '€';
  } else if (devise_name === 'Pound') {
    devise_symbol = '£';
  }

  return (
    <Card key={id} sx={{ borderLeft: '10px solid', borderColor: 'primary.main', mb: 3 }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ mr: 1 }}>
            <Link
              component={RouterLink}
              to={{
                pathname: `/${appState.customer?.slug}/${
                  currentReportTab === DeliverableReportTab.TECHNICAL ? 'deliverablesheet' : 'financialreport'
                }/${order_id}/${id}`,
              }}
              underline="none"
              sx={{ display: 'flex', gap: 2 }}
            >
              <Typography color="neutral.main">{formatDateToddMMYYY(created_at, '.')}</Typography>
              <Typography data-testid="title_date_report_item">{titleWithDateString}</Typography>
            </Link>
          </Box>
          {sheet.type === 'financial' && (
            <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
              <Typography>
                {((sheet as FinancialReport).total_financial_amount || 0).toFixed(2)} {devise_symbol}
              </Typography>
            </Box>
          )}
          <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ mr: 2 }}>{!userRoles.isCustomer && <AvatarCustom user={client} />}</Box>
            <Box sx={{ mr: 2, display: 'flex', gap: 2, minWidth: 200 }}>
              <StatusReport state={state} />
              {state === ReportStatus.FINISHED && signed_by_DM && (
                <Tooltip placement="top" title={t('signed_by_dm') as string}>
                  <img src={signedDM} alt="signed by DM" />
                </Tooltip>
              )}
            </Box>
            <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
              {pdfUrl && (
                <Tooltip title="Download">
                  <IconButton href={pdfUrl} download={`${title}.pdf`} data-testid="donwload_pdf_sheet">
                    <Download />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            {/* {(userRoles.isCustomer || userRoles.isBm || userRoles.isDeliveryCoordinator || userRoles.isConsultant) && ( */}
            <Box>
              <DeliverableReportMenu report={sheet} />
            </Box>
            {/* )} */}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default DeliverableReportsItem;
