/* eslint-disable react-hooks/exhaustive-deps */
import { Search } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Grid, Input, InputAdornment, Tooltip } from '@mui/material';
import { SearchParams } from 'api/ResourceAPI';
import UserApiService from 'api/UserApiService';
import DashedButton from 'components/DashedButton';
import EmptyState from 'components/EmptyState';
import { Divider, Layout, Loader } from 'components/index';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import User from 'types/entities/User';
import ClientCard from './ClientCard';
import ClientCreation from './ClientCreation';
import ClientEdition from './ClientEdition';
import ClientsDialogResetPassword from './ClientsDialogs/ClientsDialogResetPassword';
import ClientsDialogDeactivateUser from './ClientsDialogs/ClientsDialogDeactivateUser';

const Clients: React.FC<RouteComponentProps> = ({ match }) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const loadings = useSelector((state) => state.loadings);
  const [activeClient, setActiveClient] = useState<User>();
  const [requestSearch, setRequestSearch] = useState<string>('');
  const [clients, setClients] = useState<User[]>([]);
  const [clientArchived, setClientArchived] = useState<User[]>([]);
  const [openCreate, dispatchCreateOpen] = useReducer((prev) => !prev, false);
  const [openEdit, dispatchEditOpen] = useReducer((prev) => !prev, false);
  const { makeCall } = useApi();
  const [showArchivedClients, setShowArchivedClients] = useState<boolean>(true);

  useEffect(() => {
    const constructSearchParams = (archived: number) => {
      if (requestSearch === '') {
        return {
          is_archived: archived,
          size: -1,
        };
      }
      return {
        is_archived: archived,
        size: -1,
        'first_name,last_name,department,mail,position,direction': `:${requestSearch}:`,
      };
    };

    const delayDebounceFn = setTimeout(async () => {
      const getClients = async () => {
        if (appState.customer) {
          setClients([]);
          setClientArchived([]);
          dispatch(addLoadingAction('getClients'));
          const search = constructSearchParams(0) as SearchParams;
          const res = await makeCall(
            UserApiService.getClientsOnCustomer(appState.customer?.id, search),
            'Error while loading clients',
            'getClients'
          );
          setClients(res);
          const searchArchived = constructSearchParams(1) as SearchParams;
          const resArchived = await makeCall(
            UserApiService.getClientsOnCustomer(appState.customer?.id, searchArchived),
            'Error while loading clients',
            'getClients'
          );
          setClientArchived(resArchived);
          dispatch(removeLoadingAction('getClients'));
        }
      };
      getClients();
    }, 500);

    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer?.id, requestSearch]);

  const [t] = useTranslation();

  const handleDeleteclient = (clientId: number) => {
    const clientNotArchived = clients?.findIndex((client) => client.id === clientId);
    const clientArchive = clientArchived?.findIndex((client) => client.id === clientId);
    if (clientNotArchived !== -1) {
      const isArchived = clients[clientNotArchived].is_archived;
      setClientArchived((prevClients) => [...prevClients, { ...clients[clientNotArchived], is_archived: !isArchived }]);
      setClients((prevClients) => (prevClients || []).filter((client) => client.id !== clientId));
    } else if (clientArchive !== -1) {
      const isArchived = clientArchived[clientArchive].is_archived;
      setClients((prevClients) => [...prevClients, { ...clientArchived[clientArchive], is_archived: !isArchived }]);
      setClientArchived((prevClients) => (prevClients || []).filter((client) => client.id !== clientId));
    }
  };

  const handleClickArchived = () => {
    setShowArchivedClients(!showArchivedClients);
  };

  const archivedClientsDisplay = !!(appState.customer && clientArchived?.length);
  return (
    <Layout name="Clients" path="/client">
      <Box sx={{ display: 'flex' }}>
        <Tooltip arrow title={t<string>('Search by first name, last name, position, direction department or email')}>
          <Input
            placeholder={t('Search')}
            size="small"
            endAdornment={
              <InputAdornment position="start">
                <Search sx={{ color: 'neutral.main' }} />
              </InputAdornment>
            }
            value={requestSearch ?? ''}
            onChange={(e) => setRequestSearch(e.currentTarget.value)}
            sx={{ width: { lg: '25ch' }, mb: 3, ml: 'auto' }}
          />
        </Tooltip>
      </Box>

      <Grid container spacing={4} sx={{ mb: 5 }}>
      {(userRoles.isBm || userRoles.isDeliveryManager || userRoles.isAdmin) && (
          <Grid item xs={12} sm={6} md={3} xl={2} sx={{ display: 'flex' }}>
            <DashedButton
              content={t('Add_client')}
              onclick={() => {
                dispatchCreateOpen();
              }}
            />
          </Grid>
        )}
        {!clients && (
          <>
            <Grid item xs={12} xl={8} justifyContent="center" alignItems="center" display="flex">
              <Loader />
            </Grid>
          </>
        )}
        {clients && clients?.length === 0 && (
          <Grid item xs={12} xl="auto">
            <EmptyState title={t('No_client_found')} />
          </Grid>
        )}
        {appState.customer &&
          clients &&
          clients.map((client, index) => {
            if (!client.deactivation_date) {
              return (
                <React.Fragment key={`catalog_${String(index)}`}>
                  <Grid item xs={12} sm={6} md={3} xl={2} sx={{ display: 'flex' }}>
                    <ClientCard
                      user={client}
                      name={`${client.last_name.toLocaleUpperCase()} ${client.first_name}`}
                      position={client.position ?? ''}
                      email={client.mail}
                      handleDeleteclient={(clientId) => handleDeleteclient(clientId)}
                      dispatchEditOpen={dispatchEditOpen}
                      setActiveClient={(user: User) => setActiveClient(user)}
                      data-testid="client_active"
                      setClients={setClients}
                    />
                  </Grid>
                </React.Fragment>
              );
            }
            return null;
          })}
      </Grid>
      <Box>
        <Button
          endIcon={showArchivedClients ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={handleClickArchived}
          fullWidth
          sx={{ mb: 5 }}
        >
          Your Archived Clients
        </Button>

        <Divider color="secondary" />

        {showArchivedClients && (
          <Grid container spacing={4}>
            {archivedClientsDisplay &&
              clientArchived
                ?.filter((client) => client.customer_id === appState.customer?.id && client.is_archived)
                ?.map((client, index) => (
                  <React.Fragment key={`catalogarchived_${String(index)}`}>
                    <Grid item xs={12} sm={6} md={3} xl={2} sx={{ display: 'flex' }}>
                      <ClientCard
                        user={client}
                        name={`${client.first_name} ${client.last_name}`}
                        position={client.position ?? ''}
                        email={client.mail}
                        isArchived={client.is_archived}
                        handleDeleteclient={(clientId) => handleDeleteclient(clientId)}
                        dispatchEditOpen={dispatchEditOpen}
                        setActiveClient={(user: User) => setActiveClient(user)}
                        data-testid="client_archived"
                        setClients={setClients}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
            {clientArchived?.length === 0 && (
              <Grid item xs={12}>
                <EmptyState title={t('no_archived_clients')} />
              </Grid>
            )}
            {!clientArchived && (
              <Grid item xs={12} justifyContent="center" display="flex">
                <Loader />
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      {appState.customer && openCreate && (
        <>
          <ClientCreation
            open={openCreate}
            dispatchOpen={dispatchCreateOpen}
            setClient={(newClient) => {
              setClients([...(clients || []), newClient]);
            }}
            activeCustomer={appState.customer}
          />
        </>
      )}
      {appState.customer && activeClient && openEdit && (
        <>
          <ClientEdition
            open={openEdit}
            dispatchOpen={dispatchEditOpen}
            setClient={(newClient) => {
              setClients((clients || []).map((client) => (client.id !== newClient.id ? client : newClient)));
            }}
            activeCustomer={appState.customer}
            activeClient={activeClient}
          />
        </>
      )}
      {activeClient && <ClientsDialogResetPassword activeUser={activeClient} />}
      {activeClient && <ClientsDialogDeactivateUser activeUser={activeClient} setClients={setClients} />}
    </Layout>
  );
};

export default Clients;
