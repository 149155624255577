/* eslint-disable no-return-await */
import { ArrowForward, Search } from '@mui/icons-material';
import { Box, Button, Drawer, TextField, Tooltip } from '@mui/material';
import { handleApiError } from 'api/errors';
import MissionApiService from 'api/MissionApiService';
import OrderApiService from 'api/OrderApiService';
import OrderScopesApiService from 'api/OrderScopeApiService';
import { SearchParams } from 'api/ResourceAPI';
import UserApiService from 'api/UserApiService';
import { Layout } from 'components/index';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
import { includes } from 'lodash';
/* eslint-disable no-return-assign */
import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { clearSelectedFilterAction } from 'store/actions/filterActions';
import { addLoadingAction } from 'store/actions/loadingsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Order from 'types/entities/Order';
import OrderScope from 'types/entities/OrderScope';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import OrderCard from '../Orders/OrderCard/OrderCard';
import { closeEditDrawerAction, openEditDrawerAction } from './__context/_reducers/EditDrawer/EditDrawer';
import { gridFinishedLoadingAction, gridIsLoadingAction } from './__context/_reducers/GridHandler/GridHandler';
import { injectOrderAction } from './__context/_reducers/InjectOrder/InjectOrder';
import { injectOrderWorkunitAffectation } from './__context/_reducers/InjectOrderWorkunitAffectation/InjectOrderWorkunitAffectation';
import { injectScopes } from './__context/_reducers/InjectScopes/InjectScopes';
import { setAffectationClientsAction } from './__context/_reducers/SetAffectationClients/SetAffectationClients';
import { setAffectationConsultantsAction } from './__context/_reducers/SetAffectationConsultants/SetAffectationConsultants';
import { setAffectationDMAction } from './__context/_reducers/SetAffectationDM/SetAffectationDM';
import { bulkUpdateOrderWorkunitAction } from './__context/_reducers/UpdateOrderWorkunit/UpdateOrderWorkunit';
import {
  OrderWorkunitAffectationContext,
  OrderWorkunitAffectationProvider,
} from './__context/OrderWorkunitAffectationContext';
import AffectationGrid from './AffectationGrid/AffectationGrid';
import OrderWorkunitAffectationEdit from './AffectationGrid/OrderWorkunitAffectationEdit';

interface RouteParams {
  order_id: string;
}

const OrderWorkunitsAffectation: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const appFiltersSelected = useSelector((state) => state.filter.selected);
  const userRoles = useUserRoles();
  const loadings = useSelector((state) => state.loadings);
  const { makeCall } = useApi();
  const [t] = useTranslation();
  const history = useHistory();
  const [textSearch, setTextSearch] = useState('');
  useLayoutEffect(() => {
    dispatch(clearSelectedFilterAction());
    return () => {
      dispatch(clearSelectedFilterAction());
    };
  }, []);

  const { state, dispatch: dispatchOrderWorkunitContext } = useContext(OrderWorkunitAffectationContext);

  const openEditDrawer = () => dispatchOrderWorkunitContext(openEditDrawerAction());
  const closeEditDrawer = () => dispatchOrderWorkunitContext(closeEditDrawerAction());
  useEffect(() => {
    const getScopes = async () => {
      if (appState.customer && appState.customer.id) {
        const orderScopes: OrderScope[] = await makeCall(
          OrderScopesApiService.fetchOrderScopeById(parseInt(match.params.order_id, 10), {
            join: ['scope'],
          }),
          'Unable to retrieve scopes'
        );
        dispatchOrderWorkunitContext(injectScopes(orderScopes.map((orderScope) => orderScope.scope as Scope)));
      }
    };
    const getAffectationConsultants = async () => {
      if (appState.customer && appState.customer.id) {
        const affectableConsultants: User[] = await makeCall(
          UserApiService.fetchPossiblesConsultants(),
          'Unable to retrieve consultants'
        );
        dispatchOrderWorkunitContext(setAffectationConsultantsAction(affectableConsultants));
      }
    };
    const getClients = async () => {
      if (appState.customer) {
        const res = await makeCall(
          UserApiService.getClientsOnCustomer(appState.customer?.id, { is_archived: 0, size: -1 }),
          'Error while loading clients'
        );
        dispatchOrderWorkunitContext(setAffectationClientsAction(res));
      }
    };

    if (appState.customer?.id) {
      getScopes();
      getAffectationConsultants();
      getClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer]);

  useEffect(() => {
    const getDeliveryManagers = async () => {
      try {
        const res = await UserApiService.fetchPossiblesDeliverableTeamLeaders();

        const sortedRes = res.sort((a, b) => {
          const nameA = a.last_name.toUpperCase();
          const nameB = b.last_name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

        dispatchOrderWorkunitContext(setAffectationDMAction(sortedRes));
      } catch (error) {
        handleApiError(error);
      }
    };

    if (appState.customer?.id) getDeliveryManagers();
  }, [appState.customer?.id]);

  const [isLoading] = useState(false);
  const [workunitsSaving, setWorkunitsSaving] = useState<number>(0);

  useEffect(() => {
    const fetchOrder = async () => {
      dispatch(addLoadingAction('fetchOrder'));
      const { orderForCustomer } = await makeCall(
        OrderApiService.fetchCurrentOrdersListManagement(appState?.customer?.id as number, {
          id: Number(match.params.order_id),
          size: 1,
        })
      );

      if (orderForCustomer[0]) {
        dispatchOrderWorkunitContext(injectOrderAction(orderForCustomer[0]));
      }
    };
    if (match.params.order_id) fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.order_id]);

  const constructSearchParams = useCallback(() => {
    const searchParams: SearchParams = {
      order_id: parseInt(match.params.order_id, 10),
      size: state.pageSize,
      page: state.currentPage,
      is_archived: 0,
      join: ['scope', 'consultant', 'client', 'delivery_manager'],
      workunit_id: '>=0',
    };

    if (Object.values(appFiltersSelected).length) {
      Object.entries(appFiltersSelected).forEach(([field, value]) => {
        if (field === 'text_search') return;
        if (field.includes('_id') && value[0] && value[0] !== '') {
          searchParams[field] = value;
        } else if (value[0] && value[0] !== '') {
          searchParams[field] = `:${value}:`;
        }
      });
    }
    if (textSearch) {
      searchParams['workunit_name,workunit_reference'] = `:${textSearch}:`;
    }
    return searchParams;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.order_id, appFiltersSelected, state.currentPage, state.pageSize, textSearch]);

  const fetchOrderWorkunits = useCallback(
    async (searchParams: SearchParams) => {
      const { datas, totalPages, totalItems, currentPage } = await makeCall(
        MissionApiService.fetchForAffectationMissions(searchParams),
        'Unable to retrieve order workunits',
        'affectationGrid'
      );
      dispatchOrderWorkunitContext(injectOrderWorkunitAffectation(datas, totalItems, totalPages, currentPage));
      dispatchOrderWorkunitContext(gridFinishedLoadingAction());
    },
    [dispatchOrderWorkunitContext]
  );
  useEffect(() => {
    let mounted = true;
    dispatchOrderWorkunitContext(gridIsLoadingAction());
    if (match.params.order_id && mounted) {
      const delayDebounceFn = setTimeout(() => {
        const searchParams = constructSearchParams();
        fetchOrderWorkunits(searchParams);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
    return () => (mounted = false);
  }, [constructSearchParams, dispatchOrderWorkunitContext, fetchOrderWorkunits, match.params.order_id]);

  const [isDrawerLoading, setIsDrawerLoading] = useState<boolean>(false);
  const handleValidateSelectionDrawer = async (multiSelect: Partial<OrderWorkunit>) => {
    setIsDrawerLoading(true);
    await saveManyOrderWorkunitsChanges(multiSelect);
  };

  const saveManyOrderWorkunitsChanges = async (multiSelect: Partial<OrderWorkunit>) => {
    try {
      if (!state?.order?.id) throw Error('No registered order');

      const toSend: {
        ressources: number[];
        data: {
          consultant_id?: User['id'];
          scope_id?: User['id'];
          forecast_date?: string;
          client_id?: User['id'];
          order_id?: number;
          delivery_manager_ids?: User['id'][];
        };
      } = {
        ressources: state.selectedItems as unknown as number[],
        data: { order_id: state.order?.id },
      };
      const copiedData = { ...multiSelect };
      if (multiSelect?.consultant_id) {
        toSend.data.consultant_id = multiSelect?.consultant_id;
        const consultant = state.consultants?.find((c) => c.id === multiSelect.consultant_id);
        copiedData.consultant = consultant;
        copiedData.consultant_fullname = `${consultant?.last_name.toLocaleUpperCase()} ${consultant?.first_name}`;
      }
      if (multiSelect?.forecast_date) {
        toSend.data.forecast_date = multiSelect?.forecast_date;
      }
      if (multiSelect?.client_id) {
        toSend.data.client_id = multiSelect?.client_id;
        const client = state.clients?.find((c) => c.id === multiSelect.client_id);
        copiedData.client = client;
        copiedData.client_fullname = `${client?.last_name.toLocaleUpperCase()} ${client?.first_name}`;
      }
      if (multiSelect?.scope_id) {
        toSend.data.scope_id = multiSelect?.scope_id;
      }
      if (multiSelect?.delivery_manager_ids) {
        toSend.data.delivery_manager_ids = multiSelect?.delivery_manager_ids;
        const deliveryManagers = state.deliveryManagers?.filter((c) =>
          includes(multiSelect.delivery_manager_ids, c.id)
        );
        copiedData.delivery_manager = deliveryManagers;
        copiedData.delivery_manager_fullname = `${deliveryManagers[0]?.last_name.toLocaleUpperCase()} ${
          deliveryManagers[0]?.first_name
        }`;
      }
      if (state?.order?.id) {
        await MissionApiService.bulkUpdate(state?.order?.id as Order['id'], toSend.ressources, toSend.data, false);
        dispatchOrderWorkunitContext(bulkUpdateOrderWorkunitAction(copiedData));
      }
    } catch (error) {
      const result = (error as Error).message;
      if (result) {
        dispatch(
          setSnackbarAction({
            message: `Error saving multiples order-workunits : ${result}`,
            open: true,
            severity: 'error',
          })
        );
      } else
        dispatch(
          setSnackbarAction({ message: 'Error saving multiples order-workunits', open: true, severity: 'error' })
        );
      handleApiError(error);
    } finally {
      setIsDrawerLoading(false);
      closeEditDrawer();
    }
  };

  return (
    <>
      <Layout name="Affectation" path="/order/affectation">
        {state.order && <OrderCard order={state.order} menu={false} graph={false} kpi={false} showLink={false} />}
        <>
          <Box sx={{ display: 'flex' }}>
            {!userRoles.isBu_support && (
              <Button
                variant="contained"
                onClick={openEditDrawer}
                disabled={!state.selectedItems.length}
                sx={{ mb: 4, mr: 2 }}
              >
                Set
              </Button>
            )}
            <TextField
              placeholder={t('Search by name or reference')}
              variant="standard"
              InputProps={{
                endAdornment: <Search />,
              }}
              value={textSearch}
              onChange={(e) => setTextSearch(e.currentTarget.value)}
              className="search_textfield"
              sx={{ mb: 4, ml: 'auto' }}
            />
          </Box>
          <AffectationGrid workunitSaving={workunitsSaving} setWorkunitsSaving={setWorkunitsSaving} />
          <Box sx={{ display: 'flex' }}>
            <Tooltip title="All rows without a scope will not appear on the next view" arrow>
              <Button
                variant="contained"
                endIcon={<ArrowForward />}
                onClick={() =>
                  history.push(`/${appState.customer?.slug}/orders/workunits/summary/${match.params.order_id}`)
                }
                disabled={isLoading || workunitsSaving > 0}
                sx={{ ml: 'auto' }}
              >
                {t('Next')}
              </Button>
            </Tooltip>
          </Box>
        </>

        <Drawer
          anchor="right"
          open={state.isEditDrawerOpen}
          onClose={closeEditDrawer}
          PaperProps={{
            sx: {
              width: {
                xs: '100%',
                lg: 450,
              },
            },
          }}
        >
          <OrderWorkunitAffectationEdit
            consultants={state.consultants}
            customers={state.clients}
            deliveryManagers={state.deliveryManagers}
            validateSelectedOptions={handleValidateSelectionDrawer}
            isLoading={isDrawerLoading}
            dispatchOpen={closeEditDrawer}
          />
        </Drawer>
      </Layout>
    </>
  );
};
export default (props: any) =>
  React.useMemo(
    () => (
      <OrderWorkunitAffectationProvider>
        <OrderWorkunitsAffectation {...props} />
      </OrderWorkunitAffectationProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.location]
  );
