import { SmsOutlined } from '@mui/icons-material';
import RemoveIcon from '@mui/icons-material/Remove';
import { Tooltip } from '@mui/material';
import {
  DataGridPro as DataGrid,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { CommentCheckIcon, CommentIcon, CommentMoreIcon } from 'components/CustomIcons';

import RateComponent from 'components/Rating/RateComponent';
import { changePage, changePageSize } from 'features/Sheet/SheetContext/_reducers/Inject/Inject';
import { useSheetContext } from 'features/Sheet/SheetContext/SheetContext';
import { capitalize, formatDateLitteral } from 'helpers/format';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import DeliverableSheetWorkunit from 'types/entities/DeliverableSheetWorkunit';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import DeliverableTableValidateButtons from '../../../Deliverables/DeliverableTable/DeliverableTableValidateButtons/DeliverableTableValidateButtons';
import SubmittedCell from './Cells/SubmittedCell/SubmittedCell';
import TagsCell from './Cells/TagsCell/TagsCell';
import './SheetTechnicalTable.scss';

type SheetTechnicalTableCellType = GridCellParams<any, DeliverableSheetWorkunit, any>;

const SheetTechnicalTable = () => {
  const [t] = useTranslation();
  const { state, dispatch } = useSheetContext();
  const loadings = useSelector((state) => state.loadings.api);
  const appState = useSelector((state) => state.app);
  const [displayedColumns, setDisplayedColumns] = useState<GridColDef[]>([]);
  const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>(
    (JSON.parse(localStorage.getItem('savedColumnStatus') as string) as GridColumnVisibilityModel) ?? {}
  );

  const userIsClient = appState.user?.is_client; // TODO use customer roles ?
  const userIsBm = appState.roles?.includes('bm');
  const userIsHeadMission =
  appState.roles?.includes('delivery_coordinator') || appState.roles?.includes('delivery_manager');
  const userIsAdmin = appState.roles?.includes('admin');

  const userCanValidateMission = () => userIsBm || userIsHeadMission || userIsAdmin;

  const renderTagsButtons = (params: SheetTechnicalTableCellType) => <TagsCell {...params.row} />;

  const renderReferenceButton = (params: SheetTechnicalTableCellType) => (
    <Tooltip arrow title={params?.row?.order_workunit_reference ?? ''}>
      <>{params.row?.order_workunit_reference ? capitalize(params.row.order_workunit_reference) : ''}</>
    </Tooltip>
  );

  const renderDetailsButton = (params: SheetTechnicalTableCellType) => (
    <Tooltip arrow title={params?.row?.order_workunit_name ?? ''}>
      <>{params.row.order_workunit_name ? capitalize(params.row.order_workunit_name) : ''}</>
    </Tooltip>
  );

  const emptyCell = <RemoveIcon color="disabled" />;

  const renderCommentCell = (row: DeliverableSheetWorkunit) => (
    <>
      {row.comments && row.comments.length ? (
        <CommentCheckIcon color="success" />
      ) : (
        <CommentMoreIcon sx={{ color: 'text.primary' }} />
      )}
    </>
  );

  const onColumnVisibilityChange = (columnParams: GridColumnVisibilityModel, details: GridCallbackDetails): void => {
    setColumnVisibility(columnParams);
    localStorage.setItem('savedColumnStatus', JSON.stringify(columnParams));
  };

  const getColumns = () => {
    if (!appState.user?.is_client) {
      // TODO use customer roles ?
      columns.splice(4, 0, consultantColum);
      // columns.splice(columns.length, 0, optionnalColumnsItems);
    }
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const consultantColum: GridColDef = {
    field: 'consultant_fullname',
    // hide: true,
    headerName: t('Consultant'),
    flex: 0.8,
    filterable: true,
    sortable: false,
    type: 'string',
    renderCell: (params: SheetTechnicalTableCellType) => {
      if (params.row?.consultant_fullname) {
        return (
          <Tooltip title={`${capitalize(params.row?.consultant_fullname)}`}>
            <>{`${capitalize(params.row?.consultant_fullname)}`}</>
          </Tooltip>
        );
      }
      return <></>;
    },
    valueGetter: (params: SheetTechnicalTableCellType) =>
      params.row.consultant_fullname ? params.row.consultant_fullname : '',
  };

  const columns: GridColDef[] = [
    {
      field: 'Tags',
      headerName: t('Tags'),
      flex: 0.8,
      hide: false,
      filterable: false,
      sortable: false,

      renderCell: (params: SheetTechnicalTableCellType) => renderTagsButtons(params),
    },
    {
      field: 'order_workunit_reference',
      headerName: t('Reference'),
      flex: 0.7,
      hide: false,
      filterable: false,
      sortable: false,

      renderCell: (params: SheetTechnicalTableCellType) => renderReferenceButton(params),
    },
    {
      field: 'order_workunit_name',
      headerName: t('Deliverables'),
      filterable: false,
      sortable: false,
      hide: false,
      flex: 1.4,

      renderCell: (params: SheetTechnicalTableCellType) => renderDetailsButton(params),
    },
    {
      field: 'content',
      hide: false,
      headerName: t('Content'),
      flex: 1.4,
      filterable: false,
      sortable: false,
      renderCell: (params: SheetTechnicalTableCellType) => (
        <Tooltip title={params.row.content ?? ''}>
          <>{params.row.content ?? ''}</>
        </Tooltip>
      ),
    },
    {
      field: 'Content2',
      hide: false,
      headerName: t('Content2'),
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params: SheetTechnicalTableCellType) => (
        <Tooltip title={params.row.content2 ?? ''}>
          <>{params.row.content2 ?? ''}</>
        </Tooltip>
      ),
    },

    {
      field: 'Complexity',
      headerName: t('Complexity'),
      sortable: false,
      filterable: false,
      flex: 0.7,
      renderCell: (params: SheetTechnicalTableCellType) => (
        <>{params.row.complexity_name ? params.row.complexity_name : t('Custom')}</>
      ),
    },

    {
      field: 'Deliverable_date',
      headerName: t('Initial_Deliverable_date'),
      flex: 0.6,
      filterable: true,
      sortable: false,
      renderCell: (params: SheetTechnicalTableCellType) => (
        <>
          {params?.row?.delivery_date && formatDateLitteral(params?.row?.delivery_date)}
          {!params?.row?.delivery_date && params?.row?.forecast_date && formatDateLitteral(params?.row?.forecast_date)}
          {!params?.row?.delivery_date && !params?.row?.forecast_date && t('N/A')}
        </>
      ),
    },
    {
      field: 'Forecasted_date',
      headerName: t('New_expected_date'),
      flex: 0.6,
      filterable: false,
      sortable: false,
      renderCell: (params: SheetTechnicalTableCellType) => (
        <>
          {params?.row?.forecast_date &&
            formatDateLitteral(moment(params?.row?.forecast_date).format('YYYY/MM/DD').toString())}
          {!params?.row?.forecast_date && params?.row?.delivery_date && formatDateLitteral(params?.row?.delivery_date)}
          {!params?.row?.delivery_date && !params?.row?.forecast_date && t('N/A')}
        </>
      ),
    },
    {
      field: 'Scope',
      headerName: t('Scope'),
      flex: 0.8,
      filterable: true,
      sortable: false,
      renderCell: (params: SheetTechnicalTableCellType) => {
        if (params.row?.scope) {
          return <>{capitalize(params.row?.scope?.name)}</>;
        }
        return <>{t('N/A')}</>;
      },
    },

    {
      field: 'Client',
      hide: true,
      headerName: t('Client'),
      flex: 0.8,
      filterable: false,
      sortable: false,
      renderCell: (params: SheetTechnicalTableCellType) => {
        if (params.row?.client_fullname) {
          return (
            <Tooltip title={`${capitalize(params.row?.client_fullname)}`}>
              <>{`${capitalize(params.row?.client_fullname)}`}</>
            </Tooltip>
          );
        }

        return <></>;
      },
    },
    {
      field: 'Submitted',
      headerName: t('Consultant_submission'),
      sortable: false,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params: SheetTechnicalTableCellType) => {
        if (
          !params.row.mission_advancement_id ||
          params.row.mission_advancement_id === MissionAdvancementPossibilities.REFUSED_DC
        ) {
          return emptyCell;
        }
        if (!(params?.row?.labels && params.row?.labels.includes('cancelled')))
          return (
            <SubmittedCell
              mission_advancement_id={params.row.mission_advancement_id}
              orderWorkunit={params.row}
              order_id={state.currentDeliverableSheet?.order_id ?? 0}
              consultant_id={params.row.consultant_id ?? 0}
            />
          );
        return emptyCell;
      },
    },
    {
      field: 'Validated',
      headerName: `${t('DM Validated')} `,
      sortable: false,
      flex: 0.7,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: SheetTechnicalTableCellType) =>
        params.row.mission_advancement_id &&
        !(params?.row?.labels && params.row?.labels.includes('cancelled')) &&
        params.row.mission_advancement_id !== MissionAdvancementPossibilities.SUBMITTED &&
        params.row.mission_advancement_id !== MissionAdvancementPossibilities.REFUSED_DC ? (
          <DeliverableTableValidateButtons
            row={params.row}
            accepted={[
              MissionAdvancementPossibilities.ACCEPTATION_DC,
              MissionAdvancementPossibilities.REFUSED_CLIENT,
              MissionAdvancementPossibilities.RESERVE_CLIENT,
              MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
            ]}
            refused={[MissionAdvancementPossibilities.REFUSED_DC]}
            displayButton={[MissionAdvancementPossibilities.SUBMITTED]}
            accept={MissionAdvancementPossibilities.ACCEPTATION_DC}
            refuse={MissionAdvancementPossibilities.REFUSED_DC}
            withReserve={[MissionAdvancementPossibilities.RESERVE_DC]}
            param="mission_advancement_id"
            disabled
            isClient={false}
          />
        ) : (
          emptyCell
        ),
    },
    {
      field: 'Accepted',
      headerName: t('client_acceptance'),
      filterable: false,
      sortable: false,
      flex: 0.7,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: SheetTechnicalTableCellType) =>
        [
          MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
          MissionAdvancementPossibilities.RESERVE_CLIENT,
          MissionAdvancementPossibilities.REFUSED_CLIENT,
        ].includes(params?.row?.mission_advancement_id as number) &&
        !(params?.row?.labels && params.row?.labels.includes('cancelled')) ? (
          <DeliverableTableValidateButtons
            row={params.row}
            accepted={[MissionAdvancementPossibilities.ACCEPTATION_CLIENT]}
            refused={[MissionAdvancementPossibilities.REFUSED_CLIENT]}
            displayButton={[MissionAdvancementPossibilities.RESERVE_DC, MissionAdvancementPossibilities.ACCEPTATION_DC]}
            accept={MissionAdvancementPossibilities.ACCEPTATION_CLIENT}
            refuse={MissionAdvancementPossibilities.REFUSED_CLIENT}
            withReserve={[MissionAdvancementPossibilities.RESERVE_CLIENT]}
            param="mission_advancement_id"
            disabled={!userIsClient}
            isClient
          />
        ) : (
          emptyCell
        ),
    },
    {
      field: 'Rating',
      headerName: t('Rating'),
      flex: 0.7,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (params: SheetTechnicalTableCellType) =>
        !(
          userIsClient &&
          params.row.mission_advancement_id &&
          [
            MissionAdvancementPossibilities.REFUSED_CLIENT,
            MissionAdvancementPossibilities.RESERVE_CLIENT,
            MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
          ].includes(params.row.mission_advancement_id)
        ) ? (
          <RateComponent row={params.row as unknown as OrderWorkunit} setRating={() => Promise.resolve()} disabled />
        ) : (
          <RateComponent row={params.row as unknown as OrderWorkunit} setRating={() => Promise.resolve()} />
        ),
    },
    {
      field: 'comments',
      headerName: t('Comment'),
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      sortable: false,

      renderCell: (params: SheetTechnicalTableCellType) => renderCommentCell(params.row),
    },
  ];

  useEffect(() => {
    if (!displayedColumns.length) {
      const columnsToDisplay = getColumns();
      setDisplayedColumns(columnsToDisplay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.roles, state.deliverables, columnVisibility]);

  return (
    <DataGrid
      localeText={{ toolbarExportCSV: 'Extract in Excel', toolbarExportPrint: 'Extract in PDF' }}
      rows={state.deliverables}
      columns={displayedColumns}
      onColumnVisibilityModelChange={onColumnVisibilityChange}
      components={{
        Toolbar: GridToolbar,
      }}
      columnVisibilityModel={columnVisibility}
      autoHeight
      pagination
      className="sheet_technical-table-container"
      disableColumnMenu
      disableSelectionOnClick
      rowHeight={60}
      pageSize={state.pageSize ?? 50}
      rowsPerPageOptions={[25, 50, 100, 250]}
      page={state.currentPage}
      onPageSizeChange={(newPageSize, details: GridCallbackDetails) => {
        dispatch(changePageSize(newPageSize));
      }}
      rowCount={state.deliverables.length}
      onPageChange={(newPage) => {
        dispatch(changePage(newPage));
      }}
      loading={!!loadings}
      getRowClassName={(params) => {
        if (params.row.labels && params.row.labels.includes('cancelled')) {
          return 'cancelled_row';
        }
        return '';
      }}
    />
  );
};

export default SheetTechnicalTable;
