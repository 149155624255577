import DeliverableSheetApiService from 'api/DeliverableSheetApiService';
import MissionApiService from 'api/MissionApiService';
import MissionCommentsApiService from 'api/MissionCommentsApiService';
import NotificationApiService from 'api/NotificationApiService';
import OrderApiService from 'api/OrderApiService';
import OrderWorkunitApiService from 'api/OrderWorkunitApiService';
import { SearchParams } from 'api/ResourceAPI';
import { base64toBlob } from 'helpers/utils';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
import { cloneDeep, isEqual } from 'lodash';
import moment, { Moment } from 'moment';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  addCommentDeliverableAction,
  cancelDeliverableAction,
  changeMissionAdvancementAction,
  changeRatingAction,
  changeSearchParamsAction,
  createDeliverableAction,
  deleteCommentDeliverableAction,
  deleteDeliverableAction,
  editDeliverablesAction,
  loadDeliverablesAction,
  loadOrderAction,
  resetRatingAction,
  restoreDeliverableAction,
  updateCommentDeliverableAction,
} from 'store/actions/deliverableActions';
import { addFinancialReport, createDeliverableSheetAction } from 'store/actions/deliverableSheetActions';
import { openDialogAction } from 'store/actions/dialogsActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import FinancialReport from 'types/entities/FinancialReport';
import MissionComment from 'types/entities/MissionComment';
import MissionFrequency from 'types/entities/MissionFrequency';
import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import User from 'types/entities/User';
import { DeliverableTableType } from 'types/enums/DeliverableTableType';
import { DeliverableTabOption } from 'types/enums/DeliverableTabOption';
import { DeliverableTabPage } from 'types/enums/DeliverableTabPage';
import { MessageType } from 'types/enums/MesageType';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { OrderWorkunitOrigin } from 'types/enums/OrderWorkunitOrigin';
import { Rating } from 'types/enums/Rating';
import MissionForCreation from 'types/models/MissionForCreation';
import {
  canEveryDeliverableBeSubmitted,
  isEveryDeliverablesValidationCancellable,
} from '../services/deliverableService';
import { OrderStatus } from 'types/enums/OrderStatus';

export type FrequencyDate = { start_date: string | null; end_date: string | null };

export default function useDeliverableService() {
  const { dispatch } = useAppState();
  const { app, deliverable, filter, deliverableSheet } = useSelector((state) => state);
  const history = useHistory();
  const userRoles = useUserRoles();
  const customer = useSelector((state) => state.app.customer);

  const selectedDeliverablesModel = useSelector((state) => state.deliverable.selectedDeliverablesModel);
  const { makeCall } = useApi();

  const getDeliverableByListId = useCallback(
    async (order_id: Order['id'], listId: OrderWorkunit['id'][]): Promise<OrderWorkunit[]> => {
      const { datas: orderWorkunits } = await makeCall(
        OrderWorkunitApiService.getOrderWorkunitByOrderId(
          order_id,
          [
            'id',
            'mission_advancement_id',
            'label',
            'origin',
            'rating',
            'content',
            'content2',
            'content3',
            'content4',
            'content5',
            'workunit_name',
            'workunit_reference',
            'workunit_desc',
            'forecast_date',
            'delivery_date',
            'purchase_order',
          ],
          {
            id: listId.join(','),
            size: '-1',
            join: ['consultant', 'client', 'scope', 'order-scope', 'delivery_manager'],
          }
        )
      );
      return orderWorkunits;
    },
    [makeCall]
  );

  const changeMissionAdvancement = useCallback(
    async (
      workunitsIds: OrderWorkunit['id'][],
      missionAdvancementId: MissionAdvancementPossibilities | null,
      isBulk = false,
      selectAll = false
    ) => {
      if (!app.roles) {
        throw new Error('No roles provided');
      }
      if (!workunitsIds.length && !selectAll) {
        throw new Error('No workunit(s) id(s) provided');
      }
      if (!deliverable.order) {
        throw new Error('No order in state provided');
      }
      if (missionAdvancementId && !(missionAdvancementId in MissionAdvancementPossibilities)) {
        throw new Error('Mission advancement id provided is not valid');
      }
      const deliverables =
        deliverable?.deliverables?.filter((deliverable) => workunitsIds.includes(deliverable.id)) ?? [];

      let deliverablesToUpdates: OrderWorkunit[] = [];
      switch (missionAdvancementId) {
        case MissionAdvancementPossibilities.SUBMITTED:
          if (
            !canEveryDeliverableBeSubmitted(deliverables) &&
            !isEveryDeliverablesValidationCancellable(deliverables)
          ) {
            return;
          }
          deliverablesToUpdates = deliverables.filter((deliverable) =>
            [
              null,
              MissionAdvancementPossibilities.ACCEPTATION_DC,
              MissionAdvancementPossibilities.RESERVE_DC,
              MissionAdvancementPossibilities.REFUSED_DC,
              MissionAdvancementPossibilities.REFUSED_CLIENT,
            ].includes(deliverable.mission_advancement_id)
          );
          break;
          case MissionAdvancementPossibilities.REFUSED_DC:
            case MissionAdvancementPossibilities.RESERVE_DC:
            case MissionAdvancementPossibilities.ACCEPTATION_DC:    
          deliverablesToUpdates = deliverables.filter(
            (deliverable) =>
              deliverable.mission_advancement_id &&
              [
                MissionAdvancementPossibilities.SUBMITTED,
                null,
                MissionAdvancementPossibilities.REFUSED_CLIENT,
                MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
                MissionAdvancementPossibilities.RESERVE_CLIENT,
              ].includes(deliverable.mission_advancement_id)
          );
          break;
        case MissionAdvancementPossibilities.REFUSED_CLIENT:
        case MissionAdvancementPossibilities.RESERVE_CLIENT:
        case MissionAdvancementPossibilities.ACCEPTATION_CLIENT:
          deliverablesToUpdates = deliverables.filter(
            (deliverable) =>
              deliverable.mission_advancement_id &&
            [MissionAdvancementPossibilities.ACCEPTATION_DC, MissionAdvancementPossibilities.RESERVE_DC].includes(
                deliverable.mission_advancement_id
              )
          );
          break;
        default:
          deliverablesToUpdates = cloneDeep(deliverables);
          break;
      }
      let deliverablesToUpdatesIds = deliverablesToUpdates.map((deliverable) => deliverable.id);
      if (isBulk || selectAll) {
        deliverablesToUpdatesIds = workunitsIds;
      }
      if (deliverable.order) {
        let workunitsIds: any = null;
        if (deliverablesToUpdatesIds.length === 1) {
          workunitsIds = await makeCall(
            OrderWorkunitApiService.updateWorkunit(deliverablesToUpdatesIds[0], {
              mission_advancement_id: missionAdvancementId,
            })
          );
        } else if (deliverablesToUpdatesIds.length > 1) {
          workunitsIds = await makeCall(
            OrderApiService.bulkUpdateOrderWorkunits(deliverable.order.id, {
              order_id: deliverable.order.id,
              ressources: deliverablesToUpdatesIds,
              data: { mission_advancement_id: missionAdvancementId },
            }),
            'Error on workunits update'
          );
        }
        // else if (selectAll) {
        //   const data: {
        //     ressources?: number[];
        //     data: Partial<OrderWorkunit>;
        //     order_id: Order['id'];
        //     filters?: {
        //       select_all: boolean;
        //     };
        //   } = {
        //     order_id: deliverable.order.id,
        //     data: { mission_advancement_id: missionAdvancementId },
        //     filters: {
        //       ...filter.selected,
        //       select_all: selectAll,
        //     },
        //   };
        //   if (deliverablesToUpdatesIds.length > 0) {
        //     data.ressources = deliverablesToUpdatesIds;
        //   }
        //   // FIX ME mass edit select all
        //   // await makeCall(
        //   //   OrderApiService.bulkUpdateOrderWorkunits(deliverable.order.id, data),
        //   //   'Error on workunits update'
        //   // );
        //   await fetchOrder(deliverable.order.id);
        //   await fetchDeliverables(deliverable.order.id, { current: null });
        // }
        if (workunitsIds) {
          dispatch(
            changeMissionAdvancementAction({
              ids: deliverablesToUpdatesIds,
              mission_advancement_id: missionAdvancementId,
              validator:
                missionAdvancementId &&
                [
                  MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
                  MissionAdvancementPossibilities.RESERVE_CLIENT,
                  MissionAdvancementPossibilities.REFUSED_CLIENT,
                ].includes(missionAdvancementId)
                  ? app.user
                  : undefined,
            })
          );
          deliverablesToUpdates.forEach(async (workunit: OrderWorkunit) => {
            if (
              missionAdvancementId &&
              [MissionAdvancementPossibilities.REFUSED_CLIENT].includes(missionAdvancementId)
            ) {
              await NotificationApiService.Instance.postNotification({
                type: MessageType.mission_bad_ranking_message,
                appendix: workunit.id,
                uri: `/${app.customer?.slug}/deliverables/${workunit.order_id}`,
                wording: (workunit.workunit_reference as string) ?? (workunit.workunit_name as string),
              });
            }
            if (missionAdvancementId && [MissionAdvancementPossibilities.REFUSED_DC].includes(missionAdvancementId)) {
              await NotificationApiService.Instance.postNotification({
                type: MessageType.mission_refusal_message,
                appendix: workunit.id,
                uri: `/${app.customer?.slug}/deliverables/${workunit.order_id}`,
                wording: (workunit.workunit_reference as string) ?? (workunit.workunit_name as string),
              });
            }
            if (missionAdvancementId && [MissionAdvancementPossibilities.RESERVE_DC].includes(missionAdvancementId)) {
              await NotificationApiService.Instance.postNotification({
                type: MessageType.mission_accept_partial_message,
                appendix: workunit.id,
                uri: `/${app.customer?.slug}/deliverables/${workunit.order_id}`,
                wording: (workunit.workunit_reference as string) ?? (workunit.workunit_name as string),
              });
            }
            if (
              missionAdvancementId &&
              [MissionAdvancementPossibilities.ACCEPTATION_DC].includes(missionAdvancementId)
            ) {
              await NotificationApiService.Instance.postNotification({
                type: MessageType.mission_just_confirmed,
                appendix: workunit.id,
                uri: `/${app.customer?.slug}/deliverables/${workunit.order_id}`,
                wording: (workunit.workunit_reference as string) ?? (workunit.workunit_name as string),
              });
            }
          });
          dispatch(setSnackbarAction({ message: 'Deliverables updated', open: true, severity: 'success' }));
        }
      }
    },
    [app.customer?.slug, dispatch, makeCall, app.roles, deliverable.deliverables, deliverable.order]
  );

  const isTnM = () => {
    switch (deliverable.order?.status) {
      case OrderStatus.MATERIAL_PRODUCTION:
      case OrderStatus.MATERIAL_DELETED:
      case OrderStatus.MATERIAL_CLOSED:
      case OrderStatus.MATERIAL_DRAFT:
        return true;

      default:
        return false;
    }
  };

  const cancelDeliverables = useCallback(
    async (orderWorkunitsIds: OrderWorkunit['id'][], cancellationReason: string, selectAll: boolean) => {
      if (deliverable.order?.id) {
        const workunits = await makeCall(
          OrderWorkunitApiService.massCancelOrderWorkunits(
            deliverable.order?.id,
            orderWorkunitsIds,
            cancellationReason,
            false,
            filter.selected
          ),
          'Error on workunits update'
        );
        await OrderApiService.bulkUpdateOrderWorkunits(deliverable?.order?.id as number, {
          order_id: deliverable.order?.id,
          ressources: orderWorkunitsIds,
          data: { mission_advancement_id: null },
          // filters: {
          //   ...filter.selected,
          //   select_all: selectAll, // FIX ME mass edit select all
          // },
        });
        if (workunits.order_workunits.length > 0) {
          dispatch(
            cancelDeliverableAction({
              ids: orderWorkunitsIds,
              cancellationReason,
            })
          );
          dispatch(setSnackbarAction({ message: 'Deliverables cancelled', open: true, severity: 'success' }));
        }
        // else if (selectAll) {
        //   await fetchOrder(deliverable.order.id);
        //   await fetchDeliverables(deliverable.order.id, { current: null });
        // }
      }
    },
    [deliverable.order?.id, dispatch, makeCall, filter.selected]
  );

  const deleteDeliverables = useCallback(
    async (deliverablesIds: OrderWorkunit['id'][], selectAll = false) => {
      if (!deliverablesIds.length && !selectAll) throw Error('No deliverables ids provided.');
      await makeCall(
        OrderWorkunitApiService.archiveWorkunits(deliverablesIds, selectAll, filter.selected),
        'Error on delete workunits'
      );
      // if (selectAll && deliverable.order) {
      //   await fetchOrder(deliverable.order.id);
      //   await fetchDeliverables(deliverable.order.id, { current: null });
      // } else {
      deliverablesIds.forEach((id) => {
        dispatch(deleteDeliverableAction(id));
      });
      // }
      dispatch(setSnackbarAction({ message: 'Deliverables updated', open: true, severity: 'success' }));
    },
    [dispatch, makeCall]
  );

  const generateExcelExtract = useCallback(async () => {
    if (deliverable.isTableInReportMode) {
      dispatch(openDialogAction({ name: 'deliverableCancelReportMode', data: { confirm: () => confirmAction() } }));
    } else {
      await confirmAction();
    }
    async function confirmAction() {
      dispatch(addLoadingAction('downloadExtract'));
      const ids = selectedDeliverablesModel?.map((i) => Number(i));

      if (!deliverable.order || !app.customer) {
        return;
      }
      const { data, filename } = await makeCall(
        OrderWorkunitApiService.exportXlsx({
          customer_id: app.customer.id,
          order_id: deliverable.order.id,
          order_workunit_ids: ids,
          filters: {
            ...filter.selected,
            select_all: deliverable.selectedAllDeliverables,
          },
        })
      );
      if (data) {
        const outputFilename = filename ? `${filename}.xlsx` : `${Date.now()}.xlsx`;

        const blob = base64toBlob(data.buffer, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      }

      dispatch(removeLoadingAction('downloadExtract'));
    }
  }, [deliverable.isTableInReportMode, deliverable.order, dispatch, selectedDeliverablesModel, app.customer, makeCall]);

  const generateExcelReport = useCallback(
    async (entity: 'deliverables' | 'financial-reports') => {
      dispatch(addLoadingAction('download'));
      const ids = selectedDeliverablesModel?.map((i) => Number(i));

      if (!deliverable.order || !app.customer) {
        // TODO handle error message
        return;
      }
      const { data, filename } = await makeCall(
        OrderApiService.getExcelReport(
          deliverable.order.id,
          deliverable.selectedAllDeliverables,
          filter.selected,
          ids,
          entity
        )
      );

      if (data) {
        const outputFilename = `${filename}.xlsx` || `${Date.now()}.xlsx`;
        const blob = base64toBlob(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      }
      dispatch(removeLoadingAction('download'));
    },
    [
      dispatch,
      selectedDeliverablesModel,
      deliverable.order,
      deliverable.selectedAllDeliverables,
      app.customer,
      makeCall,
      filter.selected,
    ]
  );

  const createDeliverableSheet = useCallback(async () => {
    const ids = deliverable.selectedDeliverablesModel;
    if (!deliverable.order) {
      return;
    }
    const sheet = await makeCall(
      DeliverableSheetApiService.createTechnicalReport(deliverable.order.id, {
        order_workunit_ids: ids as number[],
        type: deliverable.currentTableType.toLowerCase() as 'technical' | 'financial',
        filters: {
          ...filter.selected,
          select_all: deliverable.selectedAllDeliverables,
        },
      })
    );
    if (sheet) {
      await Promise.all(sheet.map((i: DeliverableSheet) => DeliverableSheetApiService.generatePdf(i.id)));
      if (deliverable.currentTableType === DeliverableTableType.TECHNICAL) {
        dispatch(createDeliverableSheetAction(sheet[0]));
      }
      if (deliverable.currentTableType === DeliverableTableType.FINANCIAL) {
        dispatch(addFinancialReport([...deliverableSheet.financialReports, (sheet as FinancialReport[])[0]]));
      }

      dispatch(setSnackbarAction({ message: 'Deliverables sheet created', open: true, severity: 'success' }));
      if (sheet.length === 1) {
        const redirect = `/${app.customer?.slug}/deliverablesheet/${deliverable.order.id}/${sheet[0].id}`;
        history.push(redirect);
      }
    }
  }, [
    deliverable.selectedDeliverablesModel,
    deliverable.order,
    deliverable.selectedAllDeliverables,
    deliverable.currentTableType,
    makeCall,
    dispatch,
    deliverableSheet.financialReports,
    app.customer?.slug,
    history,
    filter.selected,
  ]);

  const addCommentDeliverable = useCallback(
    async (
      deliverable: OrderWorkunit,
      comment: {
        comment: string;
        is_private: boolean;
        mission_advancement?: MissionAdvancementPossibilities;
        rating?: string;
      }
    ) => {
      if (app.user?.is_client && comment.is_private) throw new Error('You are not allowed to make private comments');
      const deliverableComment: Partial<MissionComment> = comment?.rating
        ? {
            order_workunit_id: deliverable.id,
            comment: comment.comment,
            author_id: app.user?.id as number,
            is_private: comment.is_private,
            mission_advancement_id: deliverable.mission_advancement_id,
            rating: comment.rating,
          }
        : {
            order_workunit_id: deliverable.id,
            comment: comment.comment,
            author_id: app.user?.id as number,
            mission_advancement_id: comment.mission_advancement,
            is_private: comment.is_private,
          };

      const commentCreated = await makeCall(
        MissionApiService.addCommentToMission(deliverableComment, deliverable),
        'Error while posting new comment'
      );
      if (commentCreated) {
        dispatch(addCommentDeliverableAction({ id: deliverable.id, comment: { ...commentCreated, author: app.user } }));
        return true;
      }
      return commentCreated;
    },
    [app.user, dispatch, makeCall]
  );

  const updateCommentDeliverable = useCallback(
    async (deliverableComment: MissionComment) => {
      const commentUpdated = await makeCall(
        MissionCommentsApiService.updateComment(deliverableComment.id, deliverableComment),
        'Failed to update comment'
      );
      if (commentUpdated) {
        dispatch(
          updateCommentDeliverableAction({
            deliverableId: commentUpdated.mission_comment.order_workunit_id,
            id: commentUpdated.mission_comment.id,
            comment: { ...commentUpdated.mission_comment, author: deliverableComment.author || app.user },
          })
        );
      }
    },
    [app.user, dispatch, makeCall]
  );

  const setRatingDeliverables = useCallback(
    async (orderWorkunits: OrderWorkunit[], rate: string, comment: MissionComment | null, selectAll = false) => {
      if (!orderWorkunits.length && !selectAll) throw new Error('No deliverable selected');
      if (!rate) throw new Error('No rating selected');
      if (!deliverable.order) {
        return;
      }
      if (orderWorkunits) {
        const filteredOrderWorkunits = orderWorkunits.filter(
          (deliverable) =>
            deliverable.mission_advancement_id === MissionAdvancementPossibilities.ACCEPTATION_CLIENT ||
            deliverable.mission_advancement_id === MissionAdvancementPossibilities.RESERVE_CLIENT ||
            deliverable.mission_advancement_id === MissionAdvancementPossibilities.REFUSED_CLIENT
        );

        const res = await makeCall(
          OrderApiService.bulkUpdateOrderWorkunits(deliverable.order?.id, {
            order_id: deliverable.order?.id,
            ressources: filteredOrderWorkunits.map((deliverable) => deliverable.id),
            data: { rating: rate },
          })
        );
        if (rate === 'D' || rate === 'E') {
          await Promise.all(
            filteredOrderWorkunits.map(async (workunit) => {
              makeCall(
                NotificationApiService.Instance.postNotification({
                  type: MessageType.mission_bad_ranking_message,
                  appendix: workunit.id,
                  uri: `/${app.customer?.slug}/deliverables/${workunit.order_id}`,
                  wording: (workunit.workunit_reference as string) ?? (workunit.workunit_name as string),
                })
              );
            })
          );
        }

        if (res) {
          const orderWorkunitsIds = orderWorkunits.map((deliverable) => deliverable.id);
          dispatch(
            changeRatingAction({
              ids: orderWorkunitsIds,
              rating: rate as Rating,
              comment,
              reviewer: app.user,
            })
          );

          dispatch(setSnackbarAction({ message: 'Deliverables updated', open: true, severity: 'success' }));
        }
      }
      // else if (selectAll) {
      // FIX ME mass edit select all
      // await makeCall(
      //   OrderApiService.bulkUpdateOrderWorkunits(deliverable.order?.id, {
      //     order_id: deliverable.order?.id,
      //     ressources: orderWorkunits.map((deliverable) => deliverable.id),
      //     data: { rating: rate },
      //     filters: {
      //       ...filter.selected,
      //       select_all: selectAll,
      //     },
      //   })
      // );
      // await fetchOrder(deliverable.order?.id);
      // await fetchDeliverables(deliverable.order?.id, { current: null });
      // }
    },
    [deliverable.order, makeCall, app.customer?.slug, app.user, dispatch, filter.selected]
  );

  const duplicateDeliverables = useCallback(
    async (orderWorkunits: OrderWorkunit[]) => {
      if (deliverable.isTableInReportMode) {
        dispatch(openDialogAction({ name: 'deliverableCancelReportMode', data: { confirm: () => confirmAction() } }));
      } else {
        await confirmAction();
      }

      async function confirmAction() {
        const duplicableDeliverables = orderWorkunits.filter((deliverable) => !deliverable.workunit);
        if (!duplicableDeliverables.length) throw new Error('Selected deliverables can t be duplicated');
        const order_workunits = await makeCall(
          OrderWorkunitApiService.duplicateManyOrderWorkunit(
            duplicableDeliverables.map((deliverable) => deliverable.id)
          )
        );
        if (order_workunits) {
          // const newDeliverables = order_workunits.map((orderWorkunit: OrderWorkunit) => ({
          //   ...orderWorkunit,
          //   scope: deliverable.order_scopes.find((scope) => scope.scope_id === orderWorkunit.scope_id)?.scope,
          // }));
          const newDeliverables = order_workunits.map((orderWorkunit: OrderWorkunit) => {
            const scope =
              deliverable.order?.order_type_id === 2
                ? deliverable.order?.tmscopes?.find((scope) => scope.id === orderWorkunit.scope_id)
                : deliverable.order_scopes.find((scope) => scope.scope_id === orderWorkunit.scope_id)?.scope;

            //  console.log('deliverable: ', JSON.stringify(deliverable));
            //  console.log('deliverable.order?.order_type_id: ', deliverable.order?.order_type_id);
            return {
              ...orderWorkunit,
              scope,
            };
          });
          dispatch(createDeliverableAction(newDeliverables));
        }
      }
    },
    [dispatch, makeCall, deliverable.order_scopes, deliverable.order?.tmscopes]
  );

  const createDeliverables = useCallback(
    async (
      deliverableToCreate: Omit<Partial<MissionForCreation>, 'id'>,
      isAllowedToChangeUserInThisScope: boolean,
      currentTab: DeliverableTabOption,
      frequencyDate: FrequencyDate
    ) => {
      if (!deliverableToCreate.order_id || !deliverableToCreate.client_id || !deliverableToCreate.scope_id) {
        throw new Error('Missing required properties to create deliverable');
      }

      const createMission = (): Partial<MissionForCreation> => ({
        forecast_date:
          (deliverableToCreate['mission-frequency'] as MissionFrequency | undefined)?.id !== 2 &&
          (deliverableToCreate['mission-frequency'] as MissionFrequency | undefined)?.id !== 3
            ? moment(deliverableToCreate.forecast_date).format('YYYY-MM-DD')
            : null,
        mission_advancement_id: null as unknown as number,
        order_id: deliverableToCreate?.order_id,
        client_id: deliverableToCreate.client_id,
        scope_id: deliverableToCreate.scope_id,
        price: deliverableToCreate?.price,
        consultant_id: !isAllowedToChangeUserInThisScope ? app.user?.id : deliverableToCreate?.consultant?.id,
        workunit_desc: deliverableToCreate?.desc as string,
        workunit_accept_standard: null,
        workunit_level_standard: null,
        workunit_input: null,
        workunit_output: null,
        workunit_comment: null,
        complexity_name: deliverableToCreate?.complexity_name,
        workunit_complexity_id: deliverableToCreate?.workunit_complexity_id,
        content: deliverableToCreate.content,
        content2: deliverableToCreate.content2,
        content3: deliverableToCreate.content3,
        content4: deliverableToCreate.content4,
        content5: deliverableToCreate.content5,
        workunit: deliverableToCreate?.workunit ?? undefined,
        origin: currentTab === DeliverableTabOption.CATALOG ? OrderWorkunitOrigin.CATALOG : OrderWorkunitOrigin.CUSTOM,
        workunit_id: currentTab === DeliverableTabOption.CATALOG ? deliverableToCreate.workunit_id : undefined,
        workunit_reference:
          currentTab === DeliverableTabOption.CATALOG
            ? deliverableToCreate.workunit?.reference
            : deliverableToCreate?.workunit_reference,
        start_frequency_date: frequencyDate.start_date ?? undefined,
        end_frequency_date: frequencyDate.end_date ?? undefined,
        mission_frequency_id:
          (deliverableToCreate['mission-frequency'] as MissionFrequency | undefined)?.id ?? undefined,
        workunit_name:
          currentTab === DeliverableTabOption.CATALOG
            ? deliverableToCreate.workunit?.name
            : deliverableToCreate?.workunit_name,
        label: currentTab === DeliverableTabOption.CATALOG ? 'added' : 'custom',
        // eslint-disable-next-line max-len
        consultant_fullname: deliverableToCreate.consultant?.last_name
          ? `${deliverableToCreate.consultant?.last_name.toLocaleUpperCase()} ${
              deliverableToCreate.consultant?.first_name
            }`
          : undefined,
        client_fullname: `${deliverableToCreate.client?.last_name.toLocaleUpperCase()} ${
          deliverableToCreate.client?.first_name
        }`,
        charge: deliverableToCreate.charge,
        order_scope_id: deliverable?.order_scopes?.find(
          (orderScope) => orderScope.scope_id === deliverableToCreate.scope_id
        )?.id,
        delivery_manager_ids: deliverableToCreate.delivery_manager_ids,
        purchase_order: deliverableToCreate.purchase_order,
      });
      if (deliverableToCreate) {
        const missionToCreate = createMission();
        if (missionToCreate.mission_frequency_id && missionToCreate.mission_frequency_id > 1) {
          const startDate = moment(missionToCreate.start_frequency_date);
          const endDate = moment(missionToCreate.end_frequency_date);
          if (missionToCreate.mission_frequency_id === 2) {
            const diffInWeeks = endDate.diff(startDate, 'weeks');
            if (diffInWeeks < 1) {
              throw new Error('Invalid start / end date for selected frequency type');
            }
          } else if (missionToCreate.mission_frequency_id === 3) {
            const diffInMonths = endDate.diff(startDate, 'months');
            if (diffInMonths < 1) {
              throw new Error('Invalid start / end date for selected frequency type');
            }
          }
        }

        const orderWorkunits = await makeCall(OrderWorkunitApiService.createOrderWorkunits(missionToCreate));

        const createdOwu = orderWorkunits.map((owu: OrderWorkunit) => ({
          ...owu,
          scope: deliverableToCreate.scope,
          order_id: deliverableToCreate.order_id,
          consultant: deliverableToCreate.consultant as User,
          mission_complexity_id: deliverableToCreate?.workunit_complexity_id ?? null,
          price: deliverableToCreate?.price,
          client: deliverableToCreate.client,
          mission_advancement_id: null,
          // eslint-disable-next-line max-len
          consultant_fullname: !isAllowedToChangeUserInThisScope
            ? `${app.user?.last_name.toLocaleUpperCase()} ${app.user?.first_name}`
            : `${deliverableToCreate.consultant?.last_name.toLocaleUpperCase()} ${
                deliverableToCreate.consultant?.first_name
              }`,
          client_fullname: `${deliverableToCreate.client?.last_name.toLocaleUpperCase()} ${
            deliverableToCreate.client?.first_name
          }`,
          charge: deliverableToCreate.charge,
          complexity_name: deliverableToCreate?.complexity_name,
          delivery_manager: deliverableToCreate.delivery_manager,
          purchase_order: deliverableToCreate.purchase_order,
        }));

        dispatch(createDeliverableAction(createdOwu));
      }
    },
    [app.user?.first_name, app.user?.id, app.user?.last_name, dispatch, makeCall, deliverable]
  );

  const fetchOrder = useCallback(
    async (orderId: number) => {
      if (customer) {
        const { orderForCustomer } = await makeCall(
          OrderApiService.fetchCurrentOrdersListManagement(customer.id, {
            id: Number(orderId),
            size: 1,
          })
        );

        if (orderForCustomer[0]) {
          dispatch(loadOrderAction(orderForCustomer[0]));
        }
      }
    },
    [customer, dispatch, makeCall]
  );

  const fetchDeliverables = useCallback(
    async (orderId, prevSearchParams) => {
      if (
        orderId &&
        deliverable.currentTab !== DeliverableTabPage.REPORTS &&
        deliverable?.searchParams?.customer_id &&
        !isEqual(deliverable.searchParams, prevSearchParams.current)
      ) {
        // if (
        //   (deliverable.searchParams?.mission_advancement_id === '' ||
        //     deliverable.searchParams?.mission_advancement_id === undefined) &&
        //   prevSearchParams.current?.mission_advancement_id === ''
        // ) {
        //   delete deliverable.searchParams?.mission_advancement_id;
        // } // Reforcast passing mission_advancement_id is empty after clear filter mission_advancement_id cleared

        dispatch(addLoadingAction('table'));
        const deliverables = await makeCall(
          OrderWorkunitApiService.getOrderWorkunitByOrderId(Number(orderId), [], {
            ...deliverable.searchParams,
          }),
          'Unable to retrieve deliverables',
          'table'
        );
        if (deliverables) {
          const { datas, totalItems, totalPages } = deliverables;
          dispatch(
            loadDeliverablesAction({
              deliverables: datas,
              totalItems,
              totalPages,
            })
          );
        }
        dispatch(removeLoadingAction('table'));
        if (
          deliverable.searchParams?.mission_advancement_id === '' ||
          deliverable.searchParams?.mission_advancement_id === undefined
        ) {
          delete deliverable.searchParams?.mission_advancement_id;
        } // Reforcast passing mission_advancement_id is empty after clear filter mission_advancement_id cleared

        prevSearchParams.current = deliverable.searchParams;
      }
    },
    [deliverable.currentTab, deliverable.searchParams, dispatch, makeCall]
  );

  const editDeliverables = useCallback(
    async (
      values: Partial<OrderWorkunit>,
      orderWorkunitIds: OrderWorkunit['id'][],
      order_id: Order['id'],
      selectAll: boolean,
      modificationReason?: string
    ) => {
      let res = null;
      if (!orderWorkunitIds.length && !selectAll) throw Error('No deliverable(s) id(s) provided.');
      if (Object.values(values).length === 0) throw Error('No value to update');
      if (!order_id) throw Error('No order id provided.');
      res = await makeCall(
        MissionApiService.bulkUpdate(order_id, orderWorkunitIds, values, false, filter.selected, modificationReason)
      );

      if (res && res?.order_workunit?.length > 0) {
        // if (selectAll) {
        //   await fetchOrder(order_id);
        //   await fetchDeliverables(order_id, { current: null });
        // } else {
        // dispatch(
        //   editDeliverablesAction({
        //     orderWorkunitIds,
        //     changes: values,
        //   })
        // );
        await fetchOrder(order_id);
        // }
      }
    },
    [dispatch, fetchOrder, fetchDeliverables, makeCall, filter.selected]
  );

  const handleModifyWithReason = useCallback(
    async (body: {
      old_value: any;
      new_value: any;
      attribute: any;
      comment: string;
      order_workunit_id: OrderWorkunit['id'];
      user_id: User['id'];
    }) => {
      if (deliverable?.order?.id)
        await makeCall(OrderWorkunitApiService.sendModificationWithReason(deliverable.order.id, body));
    },
    [makeCall, deliverable.order]
  );

  const handleDeliverableSearchParams = useCallback(async () => {
    if (!app.customer) {
      return;
    }
    // TODO add sort param
    let searchParams: SearchParams = {
      customer_id: app.customer.id,
      is_archived: false,
      join: [
        'scope',
        'workunit',
        'mission-frequency',
        'mission-status',
        'mission-comments',
        'mission-comments.author',
        'mission-advancement',
        'consultant',
        'reviewer',
        'validator',
        'client',
        'order-scope',
        'delivery_manager',
      ],
      size: deliverable.pageSize,
      page: deliverable.currentPage,
    };

    if (deliverable.sortDeliverablesModel.length > 0) {
      const { field, sort } = deliverable.sortDeliverablesModel[0];
      let column_value = '';
      if (field === 'delivery_manager') {
        column_value = 'delivery_manager_fullname';
      } else if (field === 'comments') {
        column_value = 'mission-comments';
      } else if (field === 'workload') {
        column_value = 'charge';
      } else if (field === 'complexity') {
        column_value = 'complexity_name';
      } else if (field === 'amount') {
        column_value = 'price';
      } else if (field === 'deliverableDate') {
        column_value = 'delivery_date';
      } else if (field === 'forcastedDate') {
        column_value = 'forecast_date';
      } else if (field === 'scope') {
        column_value = 'scope_id';
      } else if (field === 'consultant') {
        column_value = 'consultant_fullname';
      } else if (field === 'client') {
        column_value = 'client_fullname';
      } else if (field === 'delivery_manager') {
        column_value = 'delivery_manager_fullname';
      } else if (
        field === 'submitted' ||
        field === 'dm_validation' ||
        field === 'client_acceptance' ||
        field === 'actions'
      ) {
        column_value = 'mission_advancement_id';
      } else if (field === 'tags') {
        column_value = 'label';
      } else if (field === 'purchase_order_tm') {
        column_value = 'purchase_order';
      } else {
        column_value = field;
      }

      const sortParam = `${column_value}.${sort}`;
      searchParams = {
        ...searchParams,
        sorted: sortParam,
      };
    }

    switch (deliverable.currentTableType) {
      case DeliverableTableType.FINANCIAL:
        searchParams = { ...searchParams, workunit_id: '>=0' };

        break;
      case DeliverableTableType.TECHNICAL:
      default:
        break;
    }

    if (deliverable.currentTab === DeliverableTabPage.TODO) {
      const endDate = moment().endOf('month').format('YYYY-MM-DD');
      let missionAdvancementPossibilities: any[] = [];

      if (app.roles?.includes('consultant')) {
        missionAdvancementPossibilities = [null, MissionAdvancementPossibilities.REFUSED_DC];
      }

      if (
        app.roles?.includes('bm') ||
        app.roles?.includes('delivery_coordinator') ||
        app.roles?.includes('delivery_manager') ||
        app.roles?.includes('admin')
      ) {
        missionAdvancementPossibilities = [MissionAdvancementPossibilities.SUBMITTED];
      }
      if (app.roles?.includes('customer')) {
        missionAdvancementPossibilities = [
          MissionAdvancementPossibilities.RESERVE_DC,
          MissionAdvancementPossibilities.ACCEPTATION_DC,
          MissionAdvancementPossibilities.REFUSED_CLIENT,
          MissionAdvancementPossibilities.RESERVE_CLIENT,
          MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
        ];
      }
      searchParams = {
        ...searchParams,
        forecast_date: `<=${endDate}`,
        label: '§cancelled',
        mission_advancement_id: missionAdvancementPossibilities.join(','),
        rating: '',
      };
    }

    if (filter.selected.text_search) {
      searchParams = {
        ...searchParams,
        'workunit_reference,workunit_name': `:${filter.selected.text_search}:`,
      };
    }

    if (filter.selected) {
      Object.entries(filter.selected).forEach(([fieldKey, values]) => {
        if (values.length && fieldKey === 'label') {
          if (values[0] === 'cancel') {
            deleteMissionIdOptionClickable(searchParams);
          }
          searchParams[fieldKey] = values.map((x) => `:${x}:`).join(',');
        } else if (values.length && fieldKey.includes('_start')) {
          if (Object.keys(searchParams).includes(fieldKey.replace('_start', ''))) return;

          const endDate = filter.selected[fieldKey.replace('_start', '_end')];
          let query = '';
          if (endDate && endDate.toString() === values.toString()) {
            query = values.join(',');
          } else if (endDate.length === 0) {
            // for single date selection
            query = `=${values}`;
          } else {
            query = endDate ? `>=${values};<=${endDate}` : `>=${values}`;
          }
          searchParams[fieldKey.replace('_start', '')] = query;
        } else if (values.length && fieldKey.includes('_end')) {
          if (Object.keys(searchParams).includes(fieldKey.replace('_end', ''))) return;

          const startDate = filter.selected[fieldKey.replace('_end', '_start')];
          let query = '';
          if (startDate && startDate.toString() === values.toString()) {
            query = values.join(',');
          } else {
            query = startDate ? `>=${values};<=${values}` : `<=${values}`;
          }
          searchParams[fieldKey.replace('_end', '')] = query;
        } else if (fieldKey === 'mission_advancement_id') {
          delete searchParams?.rating;
          delete filter.selected?.rating;
          delete filter.selected?.label; // when we select other label filter item is not clear ed-438
          searchParams[fieldKey] = generateMissionAdvancementFilter(values).join(',');
          if (values.includes('NOT_SUBMITTED') || (values.includes('NOT_ACCEPTED') && !filter.selected.label)) {
            if (searchParams.label) {
              searchParams.label = `${searchParams.label},§cancelled`;
            } else searchParams.label = '§cancelled';
          }
        } else if (values.length && values[0] !== '' && (fieldKey === 'scope_id' || fieldKey === 'consultant_id')) {
          searchParams[fieldKey] = values.map((v) => v).join(',');
        } else if (values.length && values[0] !== '' && fieldKey === 'delivery_manager') {
          searchParams[fieldKey] = values.map((v) => `${v}`);
        } else if (values.length && values[0] !== '' && fieldKey !== 'text_search') {
          searchParams[fieldKey] = values.map((v) => `:${v}:`).join(',');
          if (fieldKey === 'rating' && (values[0] === 'D' || values[0] === 'E')) {
            deleteMissionIdOptionClickable(searchParams);
            delete filter.selected?.label;
          }
        }
      });
    }
    dispatch(changeSearchParamsAction(searchParams));
  }, [
    app.customer,
    dispatch,
    app.roles,
    deliverable.currentPage,
    deliverable.pageSize,
    deliverable.currentTab,
    deliverable.currentTableType,
    deliverable.sortDeliverablesModel,
    filter.selected,
  ]);

  const deleteMissionIdOptionClickable = (searchParams: SearchParams) => {
    delete filter.selected?.mission_advancement_id;
    delete searchParams?.mission_advancement_id;
  };

  const setDeliverableMonths = useCallback(
    async (
      deliverables: OrderWorkunit[],
      setMonths: React.Dispatch<React.SetStateAction<{ month: string; value: string }[]>>
    ) => {
      const dates: Moment[] = [];
      deliverables.forEach((mission) => {
        if (mission.delivery_date) dates.push(moment(new Date(mission.delivery_date)));
        if (mission.forecast_date) dates.push(moment(new Date(mission.forecast_date)));
      });

      const maxDate = moment.max(dates);
      const minDate = moment.min(dates);

      const dateStart = minDate.startOf('month');
      const dateEnd = maxDate.startOf('month');
      const interim = dateStart.clone();
      const timeValues = [];
      while (dateEnd.endOf('month') >= interim.endOf('month')) {
        timeValues.push({
          month: interim.format('MMMM-YYYY'),
          value: `&delivery_date=%3E=${interim.startOf('month').format('YYYY-MM-DD')}%3B%3C=${interim
            .endOf('month')
            .format('YYYY-MM-DD')}`,
        });
        interim.add(1, 'month');
      }
      setMonths(timeValues);
    },
    []
  );

  const restoreDeliverables = useCallback(
    async (deliverables: OrderWorkunit[]) => {
      if (!deliverables.length) return;
      if (deliverables.some((deliverable) => !deliverable.label?.includes('cancelled'))) {
        throw new Error('Some deliverables are not cancelled');
      }
      const restorePromises = deliverables.map(async (deliverable) => {
        const res = await makeCall(
          OrderWorkunitApiService.updateWorkunit(deliverable.id, {
            label:
              deliverable.label
                ?.split('|')
                .filter((label) => label !== 'cancelled' && label !== '')
                .join('|') ?? null,
            cancellation_reason: null,
          })
        );
        if (res) {
          dispatch(restoreDeliverableAction(deliverable?.id));
        }
      });

      await Promise.all(restorePromises);
    },
    [dispatch, makeCall]
  );

  const handleDeliverableCancelAcceptance = async (orderWorkunit: OrderWorkunit) => {
    if (!orderWorkunit) throw new Error('No deliverable provided');
    if (
      orderWorkunit?.mission_advancement_id &&
      ![
        MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
        MissionAdvancementPossibilities.REFUSED_CLIENT,
        MissionAdvancementPossibilities.RESERVE_CLIENT,
      ].includes(orderWorkunit?.mission_advancement_id)
    )
      throw new Error(`Deliverable ${orderWorkunit.id} is not accepted by client`);
    const updated = await makeCall(
      OrderWorkunitApiService.updateWorkunit(orderWorkunit.id, {
        id: orderWorkunit.id,
        mission_advancement_id: MissionAdvancementPossibilities.ACCEPTATION_DC,
        rating: ' ',
      }),
      'Error while updating deliverable'
    );
    await handleDeliverableCancelRating(orderWorkunit, true);

    if (updated) {
      dispatch(
        setSnackbarAction({
          message: 'Deliverable acceptance cancelled',
          open: true,
          severity: 'success',
          duration: 5000,
        })
      );
      dispatch(
        changeMissionAdvancementAction({
          ids: [orderWorkunit.id],
          mission_advancement_id: MissionAdvancementPossibilities.ACCEPTATION_DC,
        })
      );
    }
  };

  const handleDeliverableCancelRating = async (orderWorkunit: OrderWorkunit, isFromCancelAcceptance = false) => {
    if (!isFromCancelAcceptance && !orderWorkunit.rating) throw new Error('Deliverable is not rated');
    const updated = await makeCall(
      OrderWorkunitApiService.updateWorkunit(orderWorkunit.id, {
        id: orderWorkunit.id,
        rating: null,
      }),
      'Error while updating deliverable'
    );

    // Commented for avoiding multiple delete calls

    // const commentFind = orderWorkunit['mission-comments'].find(
    //   (comment) => comment?.rating && comment?.author_id === orderWorkunit.last_reviewer
    // );
    // if (commentFind) {
    //   await handleDeliverableCancelComment(orderWorkunit);
    // }
    if (updated) {
      dispatch(
        setSnackbarAction({
          message: 'Deliverable  cancelled',
          open: true,
          severity: 'success',
          duration: 5000,
        })
      );
      dispatch(resetRatingAction(orderWorkunit.id));
    }
  };

  const handleDeliverableCancelComment = async (orderWorkunit: OrderWorkunit) => {
    const commentsToDelete = orderWorkunit['mission-comments'].filter(
      (comment) =>
        comment.mission_advancement_id &&
        ([
          MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
          MissionAdvancementPossibilities.REFUSED_CLIENT,
          MissionAdvancementPossibilities.RESERVE_CLIENT,
        ].includes(comment.mission_advancement_id) ||
          comment.rating) &&
        comment.author_id === orderWorkunit.last_validator
    );
    const promisesArchiveOrderWorkunitComments = commentsToDelete.map((comment) =>
      MissionApiService.archiveComments(comment.id, orderWorkunit?.id)
    );
    await Promise.all(promisesArchiveOrderWorkunitComments);
    dispatch(
      setSnackbarAction({
        message: 'Deliverable acceptance cancelled',
        open: true,
        severity: 'success',
        duration: 5000,
      })
    );
    dispatch(
      deleteCommentDeliverableAction({
        deliverableId: orderWorkunit.id,
        ids: commentsToDelete.map((comment) => comment.id),
      })
    );
  };

  const generateMissionAdvancementFilter = (statusList: string[]) => {
    const statusMissionAdvancementIds: { [key: string]: (string | null)[] } = userRoles.isCustomer
      ? {
          NOT_SUBMITTED: [',2'],
          SUBMITTED: ['1,3,4,5,6,7'],
          NOT_VALIDATED: ['1'],
          VALIDATED: ['>2'],
          VALIDATED_WITH_RESERVES: ['3'],
          REFUSED_DM: ['2'],
          NOT_ACCEPTED: ['3,4'],
          ACCEPTED: ['7'],
          ACCEPTED_RESERVES: ['6'],
          REFUSED_CLIENT: ['5'],
        }
      : {
          NOT_SUBMITTED: [',2'],
          SUBMITTED: ['1,3,4,5,6,7'],
          NOT_VALIDATED: ['1'],
          VALIDATED: ['>3'],
          VALIDATED_WITH_RESERVES: ['3'],
          REFUSED_DM: ['2'],
          NOT_ACCEPTED: ['3,4'],
          ACCEPTED: ['7'],
          ACCEPTED_RESERVES: ['6'],
          REFUSED_CLIENT: ['5'],
        };

    const missionAdvancementList = statusList.map((statusItem) => statusMissionAdvancementIds[statusItem]);
    return [...new Set(missionAdvancementList.flat())];
  };

  return {
    addCommentDeliverable,
    cancelDeliverables,
    changeMissionAdvancement,
    createDeliverables,
    createDeliverableSheet,
    deleteDeliverables,
    duplicateDeliverables,
    isTnM,
    editDeliverables,
    fetchOrder,
    fetchDeliverables,
    generateExcelExtract,
    generateExcelReport,
    getDeliverableByListId,
    handleDeliverableCancelAcceptance,
    handleDeliverableCancelComment,
    handleDeliverableCancelRating,
    handleDeliverableSearchParams,
    handleModifyWithReason,
    restoreDeliverables,
    setDeliverableMonths,
    setRatingDeliverables,
    updateCommentDeliverable,
  };
}
