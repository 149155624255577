import { intersection } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'store/hooks';
import { RoleType } from 'types/RoleType';

/**
 * React hook and test method to know if the user has specific role or group of roles
 */
export default function useUserRoles() {
  const { roles, user } = useSelector((state) => state.app);
  return useMemo(
    () => ({
      isAdmin: hasRoles(['admin'], roles),
      isBm: hasRoles(['bm'], roles),
      isBu_support: hasRoles(['bu_support'], roles),
      isConsultant: hasRoles(['consultant'], roles),
      isCustomer: hasRoles(['customer'], roles),
      isSupportBu: hasRoles(['support_bu'], roles),
      isDeliveryCoordinator: hasRoles(['delivery_coordinator'], roles),
      isDeliveryManager: hasRoles(['delivery_manager'], roles),
      isClient: user?.is_client, // TODO use customer role ??
      isDeliverableManager: hasRoles(['admin', 'bm', 'delivery_coordinator', 'delivery_manager'], roles),
      isOrderManager: hasRoles(['admin', 'bm', 'bu_support', 'delivery_manager', 'delivery_coordinator'], roles),
      isMigsoManager: hasRoles(
        ['admin', 'bm', 'bu_support', 'support_bu', 'delivery_coordinator', 'delivery_manager'],
        roles
      ),
      isManager: hasRoles(['bm', 'delivery_manager', 'bu_support', 'support_bu'], roles),
    }),
    [roles, user]
  );
}

const hasRoles = (roles: RoleType[], userRoles: RoleType[] | undefined): boolean =>
  !!userRoles && !!intersection(roles, userRoles).length;
