import { Box, Chip, Tab, Tabs } from '@mui/material';
import { BookCloseIcon, DraftIcon, ChecklistIcon, DeleteIcon, PendingIcon } from 'components/CustomIcons';
import useUserRoles from 'hooks/useUserRoles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';

import { OrdersTabState } from 'types/enums/OrdersTabState';
import useManagementHelper from '../../services/useManagementHelper';

type ManagementTabsProps = {
  currentToAssignCount: number;
};

const MaterialTabs: React.FC<ManagementTabsProps> = ({ currentToAssignCount }: { currentToAssignCount: number }) => {
  const [t] = useTranslation();
  const { isPathForProduction, isPathForQuotes, handleManagementTabChange } = useManagementHelper();
  const { state } = useAppState();
  const currentTab = useSelector((state) => state.management.pageHandler.currentTab);
  const parentTab = useSelector((state) => state.management.pageHandler.parentTab);
  const userRoles = useUserRoles();
  const currentTabs = useCallback(() => {
    const tabs = [
      {
        label: OrdersTabState.MATERIAL_DRAFT,
        value: OrdersTabState.MATERIAL_DRAFT,
        icon: <DraftIcon fontSize="small" />,
        disabled: !(userRoles.isMigsoManager && isPathForProduction()),
      },
      {
        label: OrdersTabState.MATERIAL_PRODUCTION,
        value: OrdersTabState.MATERIAL_PRODUCTION,
        icon: <PendingIcon fontSize="small" />,
        disabled: !(userRoles.isMigsoManager && isPathForProduction()),
      },
      {
        label: OrdersTabState.MATERIAL_CLOSED,
        value: OrdersTabState.MATERIAL_CLOSED,
        icon: <BookCloseIcon fontSize="small" />,
        disabled: !(userRoles.isMigsoManager && isPathForProduction()),
      },
      {
        label: OrdersTabState.MATERIAL_DELETED,
        value: OrdersTabState.MATERIAL_DELETED,
        icon: <DeleteIcon fontSize="small" />,
        disabled:
        !(userRoles.isMigsoManager && isPathForProduction()) || userRoles.isBu_support || userRoles.isSupportBu,
      },
    ];

    return tabs.filter((tab) => !tab.disabled);
  }, [currentToAssignCount, isPathForProduction, isPathForQuotes, userRoles.isMigsoManager]);

  const badgeContent = (tab: {
    label: OrdersTabState;
    value: OrdersTabState;
    disabled: boolean;
    badge?: number | undefined;
  }) =>
    tab.badge ? (
      <>
        <Box sx={{ mr: 1 }}>{t(tab.label)}</Box>
        <Chip label={currentToAssignCount} color="error" size="small" sx={{ fontWeight: 500 }} />
      </>
    ) : (
      t(tab.label)
    );

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
      {currentTab && (
        <Tabs value={currentTab}>
          {currentTabs().map((tab) => (
            <Tab
              data-testid={`management_tab_${tab.value}`}
              label={badgeContent(tab)}
              value={tab.value}
              icon={tab.icon}
              iconPosition="start"
              onClick={() => handleManagementTabChange(tab.value)}
              disabled={state.management.pageHandler.currentTab === tab.value}
            />
          ))}
        </Tabs>
      )}
    </Box>
  );
};

export default MaterialTabs;
