import { SearchParams } from 'api/ResourceAPI';
import useApi from 'hooks/useApi';
import { useCallback } from 'react';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { closeDialogAction } from 'store/actions/dialogsActions';
import HelpMenuApiService from 'api/HelpMenuApiService';
import RolesApiService from 'api/RolesApiService';

export default function useHelpMenuService() {
  const helpMenu = useSelector((state) => state.helpMenu);
  const { makeCall } = useApi();
  const { dispatch } = useAppState();

  const getHelpmenuVideoList = useCallback(
    async (
      currentPage,
      pageSize
    ): Promise<{
      totalItems: number;
      datas: [];
      totalPages: number;
    }> => {
      let sortParam = '';
      if (helpMenu.sortHelpMenuModel.length > 0) {
        const { field, sort } = helpMenu.sortHelpMenuModel[0];
        const column_value = 'created_at';
        sortParam = `${column_value}.${sort}`;
      }

      const searchParams: SearchParams = {};
      const size = pageSize;
      const page = currentPage;

      const videoList = await makeCall(HelpMenuApiService.getHelpMenuVideos(size, page, searchParams));

      const thumnailList = await makeCall(HelpMenuApiService.fetchthumbnail());

      const datas = videoList.datas.map((item1: any) => {
        // Find the corresponding item in array2 with the same id
        const matchedItem = thumnailList.presignedUrls.find((item2: any) => item2.fileKey.includes(item1.id));

        // Merge the two objects (item1 from array1 and match from array2)
        return matchedItem ? { ...item1, ...matchedItem } : item1; // If match exists, merge, else just return item1
      });

      return { totalItems: videoList.totalItems, datas, totalPages: videoList.totalPages };
    },
    [makeCall]
  );

  const getCatelogue = async () => {
    const res = await makeCall(HelpMenuApiService.getCatelogue(), 'Error while get Catelogue');
    return res;
  };

  const deleteVideos = async (videosIds: number) => {
    const res = await makeCall(HelpMenuApiService.deleteVideo(videosIds), 'Error delete video');
    if (res) {
      dispatch(setSnackbarAction({ message: 'Video deleted successfully', open: true, severity: 'success' }));
      dispatch(closeDialogAction('videoDelete'));
      return res;
    }
    dispatch(
      setSnackbarAction({ message: 'Error! Video is not deleted. Try Again later', open: true, severity: 'error' })
    );
    dispatch(closeDialogAction('videoDelete'));
    return [];
  };

  const createVideo = async (data: any, thumbnail: string, videoFile: any) => {
    const res = await makeCall(HelpMenuApiService.createVideo(data), 'Error Create Video');
    const thumbnailBase = JSON.stringify({ file: thumbnail });
    const videoId = res?.video?.video?.id;
    const videoS3Result = await makeCall(
      HelpMenuApiService.saveContentVideoS3(videoId, thumbnailBase),
      'Unable to create thumbnail'
    );

    const payload = {
      videoId,
    };
    const result = await makeCall(HelpMenuApiService.videoUpload(videoId, payload), 'Error upload Video');
    try {
      const response = await fetch(result, {
        method: 'PUT',
        headers: {
          'Content-Type': 'video/mp4', // Ensure the content type matches the video type
        },
        body: videoFile, // The video file to upload
      });

      if (response && videoS3Result?.data?.ServerSideEncryption) {
        dispatch(setSnackbarAction({ message: 'Video created successfully', open: true, severity: 'success' }));
        return 'Video created successfully';
      }
      dispatch(
        setSnackbarAction({ message: 'Error! Video is not created. Try Again later', open: true, severity: 'error' })
      );
      return 'Error! Video is not created. Try Again later';
    } catch (error) {
      return 'Error! Video is not created. Try Again later';
    }
  };

  const updateVideo = async (videoId: number, data: any, thumbnail: string, videoFile: any) => {
    const res = await makeCall(HelpMenuApiService.updateVideo(videoId, data), 'Error Update Video');
    if (thumbnail !== undefined && !thumbnail?.includes('assets/videos/thumbnails')) {
      const thumbnailBase = JSON.stringify({ file: thumbnail });
      const videoS3Result = await makeCall(
        HelpMenuApiService.saveContentVideoS3(videoId, thumbnailBase),
        'Unable to create thumbnail'
      );
      const payload = {
        videoId,
      };
      const result = await makeCall(HelpMenuApiService.videoUpload(videoId, payload), 'Error upload Video');
      try {
        const response = await fetch(result, {
          method: 'PUT',
          headers: {
            'Content-Type': 'video/mp4', // Ensure the content type matches the video type
          },
          body: videoFile, // The video file to upload
        });

        if (response && videoS3Result?.data?.ServerSideEncryption) {
          dispatch(setSnackbarAction({ message: 'Video created successfully', open: true, severity: 'success' }));
          return 'Video created successfully';
        }
        dispatch(
          setSnackbarAction({ message: 'Error! Video is not created. Try Again later', open: true, severity: 'error' })
        );
        return 'Error! Video is not created. Try Again later';
      } catch (error) {
        return 'Error! Video is not created. Try Again later';
      }
    }

    if (res?.data) {
      dispatch(setSnackbarAction({ message: 'Video Updated successfully', open: true, severity: 'success' }));
      return 'Video Updated successfully';
    }
    dispatch(
      setSnackbarAction({ message: 'Error! Video is not Updated. Try Again later', open: true, severity: 'error' })
    );
    return 'Error! Video is not Updated. Try Again later';
  };

  const fetchVideo = async (videoId: number) => {
    const res = await makeCall(HelpMenuApiService.fetchVideo(videoId), 'Error get Video');
    if (res) {
      return res;
    }
    return 'Error! Video is not Updated. Try Again later';
  };

  // USER GUIDE
  const uploadGuidePdf = async (signatureB64: any, data: any) => {
    try {
      // Step 1: Create guide
      const guideData = await makeCall(HelpMenuApiService.createGuide(data), 'Error Create User Guide');

      if (!guideData) {
        // Return immediately if guideData is not valid
        dispatch(
          setSnackbarAction({
            message: 'Error! User Guide creation failed. Please try again later.',
            open: true,
            severity: 'error',
          })
        );
        return 'Error! User Guide creation failed. Please try again later.';
      }

      const guide_id = guideData?.guide?.guide?.id;
      const payload = { guide_id };

      // Step 2: Get pre-signed URL for the file upload
      const result = await makeCall(
        HelpMenuApiService.saveContentGuideS3(guide_id, payload),
        'Unable to create Guide pdf'
      );

      console.log(result);
      // Step 3: Upload the file using the pre-signed URL
      try {
        const response = await fetch(result?.data, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/pdf', // Ensure the content type matches the pdf type
          },
          body: signatureB64, // Convert base64 to binary content
        });

        // Step 4: Check if the response is OK (status 200-299)
        if (response.ok) {
          dispatch(setSnackbarAction({ message: 'User Guide created successfully', open: true, severity: 'success' }));
          return 'User Guide created successfully';
        }

        // If the upload fails (response.ok is false)
        const errorMessage = await response.text(); // Capture the error message from the response body
        dispatch(
          setSnackbarAction({
            message: `Error during upload: ${errorMessage}`,
            open: true,
            severity: 'error',
          })
        );
        return `Error during upload: ${errorMessage}`;
      } catch (error) {
        // Handle fetch request errors (network error, timeout, etc.)
        dispatch(
          setSnackbarAction({
            message: 'Error! User Guide creation failed. Network error or timeout',
            open: true,
            severity: 'error',
          })
        );
        return 'Error! User Guide creation failed. Network error or timeout';
      }
    } catch (error) {
      // Handle errors from the makeCall or other issues
      dispatch(
        setSnackbarAction({
          message: `Error! User Guide creation failed. ${error.message}`,
          open: true,
          severity: 'error',
        })
      );
      return `Error! User Guide creation failed. ${error.message}`;
    }
  };


  const deleteGuide = async (guideIds: number) => {
    const res = await makeCall(HelpMenuApiService.deleteGuide(guideIds), 'Error delete Guide');
    if (res) {
      dispatch(setSnackbarAction({ message: 'Guide deleted successfully', open: true, severity: 'success' }));
      dispatch(closeDialogAction('guideDelete'));
      return res;
    }
    dispatch(
      setSnackbarAction({ message: 'Error! Guide is not deleted. Try Again later', open: true, severity: 'error' })
    );
    dispatch(closeDialogAction('guideDelete'));
    return [];
  };

  const updateGuide = async (guideId: number, signatureB64: string, data: any) => {
    try {
      // Step 1: Update the guide
      const guideData = await makeCall(HelpMenuApiService.updateGuide(guideId, data), 'Error creating User Guide');
      if (!guideData) {
        // Handle case where guideData is null or invalid
        dispatch(
          setSnackbarAction({
            message: 'Error! Guide creation failed. Please try again later.',
            open: true,
            severity: 'error',
          })
        );
        return 'Error! Guide creation failed. Please try again later.';
      }

      const guide_id = guideData?.userguide?.id;
      const payload = { guide_id };

      // Step 2: Get pre-signed URL for file upload
      const result = await makeCall(
        HelpMenuApiService.saveContentGuideS3(guide_id, payload),
        'Unable to create Guide pdf'
      );

      // Step 3: Upload the file using the pre-signed URL
      const response = await fetch(result?.data, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/pdf', // Ensure the content type matches the pdf type
        },
        body: Buffer.from(signatureB64, 'base64'), // Convert base64 to binary content
      });

      // Step 4: Check if the response is OK (status 200-299)
      if (response.ok) {
        dispatch(
          setSnackbarAction({
            message: 'User Guide created successfully',
            open: true,
            severity: 'success',
          })
        );
        return 'User Guide created successfully';
      }

      // Handle failed response from the upload
      const errorMessage = await response.text(); // Optionally capture the error message from the response body
      dispatch(
        setSnackbarAction({
          message: `Error during upload: ${errorMessage}`,
          open: true,
          severity: 'error',
        })
      );
      return `Error during upload: ${errorMessage}`;
    } catch (error) {
      // Step 5: Handle errors from makeCall or other issues
      dispatch(
        setSnackbarAction({
          message: `Error! User Guide creation failed. ${error.message}`,
          open: true,
          severity: 'error',
        })
      );
      return `Error! User Guide creation failed. ${error.message}`;
    }
  };

  const getHelpmenuGuideList = useCallback(
    async (
      currentPage,
      pageSize
    ): Promise<{
      totalItems: number;
      datas: [];
      totalPages: number;
    }> => {
      let sortParam = '';
      if (helpMenu.sortHelpMenuModel.length > 0) {
        const { field, sort } = helpMenu.sortHelpMenuModel[0];
        const column_value = 'created_at';
        sortParam = `${column_value}.${sort}`;
      }

      const searchParams: SearchParams = {};
      const size = pageSize;
      const page = currentPage;
      const { totalItems, datas, totalPages } = await makeCall(
        // HelpMenuApiService.getHelpMenuVideos(size, page, sortParam, searchParams)
        HelpMenuApiService.getHelpMenuGuides(size, page, searchParams)
      );
      return { totalItems, datas, totalPages };
    },
    [makeCall]
  );

  const getHelpmenuUserGuideList = useCallback(
    async (
      currentPage,
      pageSize,
      roleName: string
    ): Promise<{
      totalItems: number;
      datas: [];
      totalPages: number;
    }> => {
      let sortParam = '';
      if (helpMenu.sortHelpMenuModel.length > 0) {
        const { field, sort } = helpMenu.sortHelpMenuModel[0];
        const column_value = 'created_at';
        sortParam = `${column_value}.${sort}`;
      }

      const searchParams: SearchParams = {};
      const size = pageSize;
      const page = currentPage;
      const { totalItems, datas, totalPages } = await makeCall(
        HelpMenuApiService.getHelpMenuUserGuides(size, page, searchParams, roleName)
      );
      return { totalItems, datas, totalPages };
    },
    [makeCall]
  );

  const getRoles = async () => {
    const res = await makeCall(RolesApiService.fetchAll(true), 'Unable to retrieve roles');
    return res;
  };

  const getGuidePdf = async (guideIds: number) => {
    const data = await makeCall(HelpMenuApiService.fetchGuidepdf(guideIds), 'Unable to retrieve User Guide');
    if (data?.length > 0) {
      dispatch(
        setSnackbarAction({ message: 'The PDF user guide for opening a new tab', open: true, severity: 'success' })
      );
    } else {
      dispatch(setSnackbarAction({ message: 'Item not found', open: true, severity: 'error' }));
    }

    return data;
  };

  return {
    getHelpmenuVideoList,
    getCatelogue,
    deleteVideos,
    createVideo,
    updateVideo,
    fetchVideo,
    uploadGuidePdf,
    getRoles,
    getHelpmenuGuideList,
    deleteGuide,
    getGuidePdf,
    getHelpmenuUserGuideList,
    updateGuide,
  };
}
