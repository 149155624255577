import { Preview } from '@mui/icons-material';
import { Avatar, Box, Checkbox, Chip, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { Loader } from 'components';
import Rating from 'components/Rating/Rating';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import { capitalize, stringToColor } from 'helpers/format';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import MissionComment from 'types/entities/MissionComment';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import User from 'types/entities/User';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';

type DeliverableDetailsCommentCardProps = {
  author: User;
  id?: number;
  mission_id?: number;
  comment?: string;
  created_at?: string;
  is_private?: boolean;
  mission_advancement_id: MissionAdvancementPossibilities | null;
  mission: OrderWorkunit;
  missionComment?: MissionComment;
};

const DeliverableDetailsCommentCard: React.FC<DeliverableDetailsCommentCardProps> = ({
  author,
  comment,
  created_at = new Date().toISOString(),
  is_private,
  mission_advancement_id,
  id,
  mission_id,
  mission,
  missionComment,
}) => {
  const [t] = useTranslation();

  const appState = useSelector((state) => state.app);
  const { updateCommentDeliverable } = useDeliverableService();

  const [changeStatusLoading, setChangeStatusLoading] = useState<boolean>(false);

  const userIsClient = appState.roles?.toString() === ['customer'].toString();
  const userIsConsultant = appState.roles?.toString() === ['consultant'].toString();
  const userIsBu_support = appState.roles?.toString() === ['bu_support'].toString();
  const isUserManagingWorkunit =
  appState.roles?.toString().includes('delivery_coordinator') ||
  appState.roles?.toString().includes('delivery_manager') ||
    appState.roles?.toString().includes('bm') ||
    appState.roles?.toString().includes('admin');

  const changeStatusOfComment = async () => {
    if (missionComment) {
      setChangeStatusLoading(true);

      const commentUpdated: MissionComment = {
        ...missionComment,
        is_private: !is_private,
      };
      await updateCommentDeliverable(commentUpdated);
      setChangeStatusLoading(false);
    }
  };

  const getColor = () => {
    if (mission_advancement_id === MissionAdvancementPossibilities.REFUSED_DC) return 'rgba(226, 10, 28, 0.43)';
    if (mission_advancement_id === MissionAdvancementPossibilities.RESERVE_DC && !userIsClient) return '#ef9400';
    if (mission_advancement_id === MissionAdvancementPossibilities.REFUSED_CLIENT) return '#e20a1c';
    if (mission_advancement_id === MissionAdvancementPossibilities.RESERVE_CLIENT) return '#ef9400';
    if (mission_advancement_id && mission_advancement_id >= MissionAdvancementPossibilities.ACCEPTATION_CLIENT)
      return '#0acb8f';
    if (
      mission_advancement_id &&
      (mission_advancement_id <= MissionAdvancementPossibilities.ACCEPTATION_DC ||
        (userIsClient && mission_advancement_id === MissionAdvancementPossibilities.RESERVE_DC))
    )
      return '#0acb8f';
    return '#a4a4a4';
  };

  const getLabel = () => {
    if (!mission_advancement_id) return t('Not_submited');
    if (mission_advancement_id === MissionAdvancementPossibilities.SUBMITTED) return t('Submitted');
    if (mission_advancement_id === MissionAdvancementPossibilities.REFUSED_DC && !userIsClient) return t('Refused_DC');
    if (mission_advancement_id === MissionAdvancementPossibilities.RESERVE_DC && !userIsClient) return t('Reserve_DC');
    if (mission_advancement_id === MissionAdvancementPossibilities.REFUSED_CLIENT) return t('Refused_customer');
    if (mission_advancement_id === MissionAdvancementPossibilities.RESERVE_CLIENT) return t('Reserve_customer');
    if (
      mission_advancement_id &&
      (mission_advancement_id === MissionAdvancementPossibilities.ACCEPTATION_DC ||
        (userIsClient && mission_advancement_id === MissionAdvancementPossibilities.RESERVE_DC))
    )
    return t('Validated_DC');
    if (mission_advancement_id && mission_advancement_id >= MissionAdvancementPossibilities.ACCEPTATION_CLIENT)
      return t('Validated_customer');

    return t('Validated_customer');
  };

  const isUserAbleToChangeCommentStatus = () => {
    if (author?.is_client) return false;
    if (author?.id === appState?.user?.id) {
      if (userIsConsultant || userIsBu_support) return false;
      return true;
    }
    if (author?.id !== appState.user?.id) {
      if (author?.id === mission.consultant_id && isUserManagingWorkunit) return true;
      if (author?.id === mission.client_id && isUserManagingWorkunit) return false;
      return false;
    }
    return false;
  };

  const isAbleToChangeCommentToPublic = isUserAbleToChangeCommentStatus();

  return (
    <Box sx={{ border: '2px solid', borderColor: getColor(), borderRadius: '10px', p: 2, mb: 3 }}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ mr: 1 }}>
          {author && !author.avatar_uri && (
            <Avatar
              sx={{
                backgroundColor: `${stringToColor(author?.first_name)}`,
                color: `${stringToColor(author?.first_name, 'color')}`,
              }}
            >
              {` ${author.first_name.charAt(0)}${author.last_name.charAt(0)}`}
            </Avatar>
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Box>
              <Typography>
                {author && author.avatar_uri && <Avatar src={author.avatar_uri} alt={t('Active_user_avatar')} />}
                {`${author.first_name} ${author.last_name}`}
              </Typography>
              <Typography variant="caption" color="neutral.main">
                {moment(created_at).fromNow()}
              </Typography>
            </Box>
            <Box sx={{ ml: 'auto' }}>
              {missionComment?.rating ? (
                <Rating size="small" rating={missionComment?.rating} />
              ) : (
                <Chip label={getLabel()} sx={{ backgroundColor: getColor(), color: '#ffff' }} />
              )}
            </Box>
          </Box>
          <Box sx={{ wordBreak: 'break-all', mb: 1 }}>{comment}</Box>
          {!appState?.user?.is_client && !is_private && (
            <Chip label={t('External_broadcasted')} color="primary" size="small" />
          )}
          {isAbleToChangeCommentToPublic && (
            <FormGroup>
              <FormControlLabel
                control={
                  changeStatusLoading ? (
                    <Loader size={20} />
                  ) : (
                    <Checkbox color="primary" checked={!is_private} onClick={() => changeStatusOfComment()} />
                  )
                }
                label={capitalize(t('Diffuse_external')) as string}
              />
            </FormGroup>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default DeliverableDetailsCommentCard;
