import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ModalCustom from 'components/Modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction, openDialogAction } from 'store/actions/dialogsActions';
import { useAppState } from 'store/Provider';
import { setActiveOrderIdAction } from '../../../store/actions/managementActions';
import useManagementService from '../services/useManagementService';

const ManagementCloseDialog = () => {
  const { state, dispatch } = useAppState();
  const { dialogs, management, loadings } = state;
  const isOpen = dialogs.managementCloseDialog;
  const isCancelReasonOpen = dialogs.cancelReasonDialog;
  const [isConfirm, setIsConfirm] = useState<boolean | null>(null);
  const [reason, setReason] = useState<string>('');
  const { closeOrder } = useManagementService();
  const [t] = useTranslation();
  const confirmText = 'OK';
  const cancelText = 'Cancel';
  const loading = () => !!loadings[`close_${management.activeOrderId}`] || !!loadings.orders;

  const onConfirm = async () => {
    onClose();
    dispatch(openDialogAction({ name: 'cancelReasonDialog' }));
    setReason('');
  };

  const onClose = () => {
    dispatch(closeDialogAction('managementCloseDialog'));
  };

  const onCloseReason = () => {
    dispatch(closeDialogAction('cancelReasonDialog'));
    dispatch(setActiveOrderIdAction(null));
    setTimeout(() => {
      setIsConfirm(null);
    }, 1000);
  };

  const onCancelReasonConfirm = async () => {
    if (!reason.trim()) {
      alert(t('Reason is mandatory.'));
      return;
    }
    const response = await closeOrder(management.activeOrderId, reason);
    if (response?.error) {
      setIsConfirm(null);
      onCloseReason();
    } else {
      setIsConfirm(true);
    }
  };

  const bodyIsConfimed = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <CheckCircleIcon color="success" fontSize="large" sx={{ mb: 1 }} />
      <Typography color="textPrimary">
        {' '}
        {t(`This order with order ID ${management.activeOrderId} is closed`)}
      </Typography>
      <Typography variant="caption" color="textPrimary" fontStyle="italic">
        {t('You can always reopen it from the order close page')}
      </Typography>
    </Box>
  );

  const bodyIsNotConfimed = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <ErrorIcon color="error" fontSize="large" sx={{ mb: 1 }} />
      <Typography color="error">{t('An error occured !')}</Typography>
    </Box>
  );

  const bodyDefault = (
    <Box>
      {/* <Typography color="textPrimary">{t('Do you want to close this order ?')}</Typography> */}
      <Typography color="textPrimary">
        {t(
          `You are about to close the order ${management.activeOrderId},  ` +
          `all deliverables not revised by your client will be cancelled. Would you like to proceed ?`
        )}
      </Typography>
    </Box>
  );

  const bodyCancelReasonDefault = (
    <Box>
      <Typography color="textPrimary" sx={{ mb: 2 }}>
        {t('Please provide a reason for cancellation:')}
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={4}
        placeholder={t('Enter reason here...')}
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        autoFocus
      />
    </Box>
  );

  const bodyContent = () => {
    switch (isConfirm) {
      case true:
        return bodyIsConfimed;
      case false:
        return bodyIsNotConfimed;
      default:
        return bodyDefault;
    }
  };

  const bodyCancelReasonContent = () => {
    switch (isConfirm) {
      case true:
        return bodyIsConfimed;
      case false:
        return bodyIsNotConfimed;
      default:
        return bodyCancelReasonDefault;
    }
  };

  const body = (
    <DialogContent>
      <DialogContentText>{bodyContent()}</DialogContentText>
    </DialogContent>
  );

  const bodyCancelReason = (
    <DialogContent>
      <DialogContentText>{bodyCancelReasonContent()}</DialogContentText>
    </DialogContent>
  );

  const actionsIsConfimed = (
    <Stack direction="row" sx={{ flex: 'auto', justifyContent: 'center' }}>
      <Button variant="contained" onClick={onCloseReason}>
        {t('Understood')}
      </Button>
    </Stack>
  );

  const actionsDefault = (
    <Stack direction="row" spacing={2}>
      <Button variant="outlined" onClick={onClose} disabled={loading()}>
        {cancelText}
      </Button>

      <LoadingButton variant="contained" onClick={onConfirm} loading={loading()}>
        {confirmText}
      </LoadingButton>
    </Stack>
  );

  const actionsReasonDefault = (
    <Stack direction="row" spacing={2}>
      <Button variant="outlined" onClick={onCloseReason} disabled={loading()}>
        {cancelText}
      </Button>
      <LoadingButton
        variant="contained"
        onClick={(e) => onCancelReasonConfirm()}
        loading={loading()}
        disabled={reason.trim() === ''}
      >
        {confirmText}
      </LoadingButton>
    </Stack>
  );

  const actionsContent = () => {
    switch (isConfirm) {
      case true:
        return actionsIsConfimed;
      case false:
        return null;
      default:
        return actionsDefault;
    }
  };

  const actionsReasonContent = () => {
    switch (isConfirm) {
      case true:
        return actionsIsConfimed;
      case false:
        return null;
      default:
        return actionsReasonDefault;
    }
  };

  const actions = <DialogActions>{actionsContent()}</DialogActions>;

  const actionsCancelReason = <DialogActions>{actionsReasonContent()}</DialogActions>;

  return (
    <>
      <ModalCustom
        open={isOpen}
        modalName="managementCloseDialog"
        onClose={onClose}
        onConfirm={onConfirm}
        disabledConfirm={isConfirm !== null}
        titleText="Close order"
        body={body}
        actions={actions}
        size="md"
      />
      <ModalCustom
        open={isCancelReasonOpen}
        modalName="cancelReasonDialog"
        onClose={onCloseReason}
        onConfirm={onCancelReasonConfirm}
        titleText={t('Reason for Cancellation')}
        body={bodyCancelReason}
        actions={actionsCancelReason}
        size="md"
      />
    </>
  );
};

export default ManagementCloseDialog;
