import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import React from 'react';
import { useSelector } from 'store/hooks';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { isDeliverableCancelled } from '../../../services/deliverableService';
import DeliverableTableValidateButtons from '../../DeliverableTableValidateButtons/DeliverableTableValidateButtons';
import EmptyCell from '../EmptyCell/EmptyCell';

const AcceptedCell = React.memo(({ row }: any) => {
  const { canAccept, canManagerAccept } = useDeliverableRights([row]);

  const clientConnection = useSelector((state) => state.deliverable.order?.client_connection);
  const isVisible = () =>
    ![MissionAdvancementPossibilities.SUBMITTED, MissionAdvancementPossibilities.REFUSED_DC, null].includes(
      row.mission_advancement_id
    ) && !isDeliverableCancelled(row);
  return (
    <>
      {isVisible() ? (
        <DeliverableTableValidateButtons
          row={row}
          accepted={[MissionAdvancementPossibilities.ACCEPTATION_CLIENT]}
          refused={[MissionAdvancementPossibilities.REFUSED_CLIENT]}
          displayButton={[MissionAdvancementPossibilities.RESERVE_DC, MissionAdvancementPossibilities.ACCEPTATION_DC]}
          accept={MissionAdvancementPossibilities.ACCEPTATION_CLIENT}
          refuse={MissionAdvancementPossibilities.REFUSED_CLIENT}
          withReserve={[MissionAdvancementPossibilities.RESERVE_CLIENT]}
          param="accepted"
          disabled={!clientConnection ? !(canManagerAccept || canAccept) : !canAccept}
          isClient
        />
      ) : (
        <EmptyCell />
      )}
    </>
  );
});

export default AcceptedCell;
