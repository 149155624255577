import RemoveIcon from '@mui/icons-material/Remove';
import { Loader } from 'components';
import { CheckIcon } from 'components/CustomIcons';
/* eslint-disable-next-line max-len */
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import useUserRoles from 'hooks/useUserRoles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import DeliverableSheetWorkunit from 'types/entities/DeliverableSheetWorkunit';
import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import User from 'types/entities/User';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';

type Props = {
  mission_advancement_id: number;
  orderWorkunit: OrderWorkunit | DeliverableSheetWorkunit;
  order_id: Order['id'];
  consultant_id: User['id'];
};

const SubmittedCell = (props: Props) => {
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const { mission_advancement_id, orderWorkunit } = props;
  const { canSubmit } = useDeliverableRights();
  const { changeMissionAdvancement } = useDeliverableService();
  const [loading, setIsLoading] = useState(false);

  const submitOrderWorkunit = async (): Promise<void> => {
    await changeMissionAdvancement([orderWorkunit.id], MissionAdvancementPossibilities.SUBMITTED);
  };

  const submitOrderWU = async () => {
    setIsLoading(true);
    await submitOrderWorkunit();
    setIsLoading(false);
  };
  const emptyCell = <RemoveIcon color="disabled" />;
  const [t] = useTranslation();
  return (
    <div className="cell-submitted icons">
      {loading && <Loader size={20} />}

      {/* eslint-disable-next-line max-len */}
      {!loading && mission_advancement_id > 0 && mission_advancement_id !== MissionAdvancementPossibilities.REFUSED_DC && (
        <div className="icon-container">
          <CheckIcon color="success" />
        </div>
      )}
      {!loading &&
        mission_advancement_id === null &&
        appState.roles?.toString() === ['customer'].toString() &&
        emptyCell}
      {!loading &&
        mission_advancement_id === MissionAdvancementPossibilities.REFUSED_DC &&
        appState.roles?.toString() !== ['customer'].toString() && (
          <button
            aria-disabled={!canSubmit}
            onClick={async () => submitOrderWU()}
            disabled={!canSubmit}
            className="submit-button reverse small-button very_small"
            type="button"
          >
            {t('Re-submit')}
          </button>
        )}
      {!loading &&
        mission_advancement_id === null &&
        mission_advancement_id !== MissionAdvancementPossibilities.REFUSED_DC &&
        !userRoles.isCustomer &&
        !userRoles.isSupportBu &&
        !userRoles.isBu_support && (
          <button
            aria-disabled={!canSubmit}
            onClick={async () => submitOrderWU()}
            disabled={!canSubmit}
            className="submit-button reverse-green small-button"
            type="button"
          >
            {t('Submit')}
          </button>
        )}
    </div>
  );
};
export default SubmittedCell;
