import FolderIcon from '@mui/icons-material/Folder';
import {
  AdminIcon,
  CatalogsIcon,
  ClientsIcon,
  DashboardIcon,
  ManagementIcon,
  OrdersIcon,
} from 'components/CustomIcons';
import { AdminDashboard, Catalogs, Clients, Dashboard, Management, HelpMenu } from 'features';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import User from 'types/entities/User';
import Documents from '../../features/Documents/Documents';
import { CloudTempleLogo, Resources } from './icons';

export const menuCTLogo = [{ name: 'CloudTempleLogo', path: '/#', icon: <CloudTempleLogo /> }];

export const menuTopEntries = [];

const routes = [
  {
    id: 7,
    name: 'Dashboard',
    path: '/dashboard',
    Component: Dashboard,
    icon: <DashboardIcon fontSize="medium" />,
    subItems: [],
    isActiveOn: ['/dashboard'],
  },
  {
    id: 1,
    name: 'Deliverables',
    path: '/orders/production',
    search: '?status=production',
    Component: Management,
    icon: <OrdersIcon fontSize="medium" />,
    subItems: [],
    isActiveOn: ['/orders/production'],
  },
  {
    id: 2,
    name: 'Catalogs',
    path: '/catalogs',
    Component: Catalogs,
    icon: <CatalogsIcon fontSize="medium" />,
    subItems: [],
    isActiveOn: ['/catalogs'],
  },
  {
    id: 3,
    name: 'Clients',
    path: '/clients',
    Component: Clients,
    icon: <ClientsIcon fontSize="medium" />,
    subItems: [],
    isActiveOn: ['/clients'],
  },
  {
    id: 4,
    name: 'Quotes',
    path: '/orders/quotes',
    search: '?status=drafted',
    Component: Management,
    icon: <OrdersIcon fontSize="medium" />,
    subItems: [],
    isActiveOn: ['/catalogs?create_quote=true', '/orders/quotes'],
  },
  {
    id: 10,
    name: 'Management',
    path: '/orders/production',
    search: '?status=production',
    Component: Management,
    icon: <ManagementIcon fontSize="medium" />,
    subItems: [],
    isActiveOn: ['/orders/production'],
  },
  {
    id: 11,
    name: 'Deliverables',
    path: '/orders/production',
    search: '?status=production',
    Component: Management,
    icon: <ManagementIcon fontSize="medium" />,
    subItems: [],
    isActiveOn: ['/orders/production'],
  },
  {
    id: 12,
    name: 'Docs',
    path: '/documents',
    Component: Documents,
    icon: <FolderIcon fontSize="medium" />,
    isActiveOn: ['/documents'],
    positionBottom: true,
  },
  {
    id: 6,
    name: 'Settings',
    path: '/administration',
    Component: AdminDashboard,
    icon: <AdminIcon fontSize="medium" />,
    isActiveOn: ['/administration'],
    positionBottom: true,
  },
  {
    id: 13,
    name: 'Tutorials',
    path: '/helpmenu',
    Component: HelpMenu,
    icon: <Resources />,
    isActiveOn: ['/helpmenu'],
    positionBottom: true,
  },
];

export type RouteType = {
  id: number;
  name: string;
  path: string;
  search?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.FC<RouteComponentProps<any>>;
  icon: JSX.Element;
  isActiveOn: string[];
  positionBottom?: boolean;
};

export function getRoutesByRole(userRoles: any, subDomainSlug: string, activeUser?: User | undefined): RouteType[] {
  let itemsToGet = new Set<number>();

  if (userRoles.isConsultant) {
    itemsToGet = new Set([7, 11, 12, 13, ...itemsToGet]);
  }
  if (userRoles.isDeliveryCoordinator) {
    itemsToGet = new Set([...[7, 10, 12, 13], ...itemsToGet]);
  }
  if (userRoles.isDeliveryCoordinator  && userRoles.isConsultant) {
    itemsToGet.delete(11);
  }
  if (userRoles.isBm || userRoles.isDeliveryManager || userRoles.isBu_support || userRoles.isSupportBu) {
    itemsToGet = new Set([...[7, 2, 3, 4, 10, 12, 13], ...itemsToGet]);
  }

  if (userRoles.isCustomer) {
    itemsToGet = new Set([7, 11, 12, 13, ...itemsToGet]);
  }

  if (userRoles.isAdmin) {
    itemsToGet = new Set([...[7, 2, 3, 4, 12, 6, 10, 13], ...itemsToGet]);
  }

  if (userRoles.isBu_support || userRoles.isSupportBu) {
    itemsToGet.delete(3);
    itemsToGet.delete(4);
  }

  if (userRoles.isSupportBu) {
    itemsToGet.delete(2);
  }

  if (
    !userRoles.isAdmin &&
    !userRoles.isCustomer &&
    !userRoles.isBm &&
    !userRoles.isConsultant &&
    !userRoles.isDeliveryCoordinator &&
    !userRoles.isBu_support &&
    !userRoles.isSupportBu
  ) {
    itemsToGet = new Set([...[7, 12, 13], ...itemsToGet]);
  }

  const routeToDisplay: RouteType[] = [];
  routes.forEach((route) => (itemsToGet.has(route.id) ? routeToDisplay.push(route) : route));
  // eslint-disable-next-line arrow-body-style
  const routesWithSlug = routeToDisplay.map((r) => {
    return {
      ...r,
      path: `/${subDomainSlug}${r.path}`,
      // eslint-disable-next-line arrow-body-style
    };
  });
  return routesWithSlug;
}
