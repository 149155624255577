import { Button } from '@mui/material';
import { Loader } from 'components';
import { CheckIcon } from 'components/CustomIcons';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import { isDeliverableCancelled } from 'features/Deliverables/services/deliverableService';
import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import useUserRoles from 'hooks/useUserRoles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { openDialogAction } from 'store/actions/dialogsActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import EmptyCell from '../EmptyCell/EmptyCell';
import { setSnackbarAction } from 'store/actions/snackbarActions';

const SubmittedCell = React.memo((row: OrderWorkunit) => {
  const [t] = useTranslation();
  const { changeMissionAdvancement } = useDeliverableService();
  const { canSubmit } = useDeliverableRights([row]);
  const { dispatch } = useAppState();
  const userRoles = useUserRoles();
  const stateReportMode = useSelector((state) => state.deliverable.isTableInReportMode);
  const loadings = useSelector((state) => state.loadings);

  // TODO export this
  const isSubmitted = () => {
    if (row.mission_advancement_id === MissionAdvancementPossibilities.REFUSED_DC && userRoles.isBu_support) {
      return true;
    }
    return (
      row.mission_advancement_id &&
      row.mission_advancement_id > 0 &&
      row.mission_advancement_id !== MissionAdvancementPossibilities.REFUSED_DC
    );
  };

  const getButtonText = () => (row.mission_advancement_id === null ? 'Submit' : 'Re-submit');
  const getButtonColor = () => (row.mission_advancement_id === null ? 'success' : 'primary');

  const onClick = () => {
    const anyIsNull = row.consultant_id === null || row.client_id === null;
    if (anyIsNull) {
      dispatch(
        setSnackbarAction({
          message:
            'The selected deliverable must have a consultant and client assigned ' +
            'before submitting. Please add the missing assignments.',
          open: true,
          severity: 'info',
        })
      );
      return false;
    }

    if (stateReportMode) {
      dispatch(openDialogAction({ name: 'deliverableCancelReportMode', data: { confirm: () => confirmAction(row) } }));
    } else {
      confirmAction(row);
    }

    function confirmAction(row: OrderWorkunit) {
      dispatch(addLoadingAction(`changeMissionAdvancement${row.id}${MissionAdvancementPossibilities.SUBMITTED}`));
      changeMissionAdvancement([row.id], MissionAdvancementPossibilities.SUBMITTED).then(() => {
        dispatch(removeLoadingAction(`changeMissionAdvancement${row.id}${MissionAdvancementPossibilities.SUBMITTED}`));
      });
    }

    return true;
  };

  if (isDeliverableCancelled(row)) return <EmptyCell />;

  return (
    <>
      <div className="cell-submitted icons">
        {loadings[`changeMissionAdvancement${row.id}${MissionAdvancementPossibilities.SUBMITTED}`] ? (
          <Loader size={20} />
        ) : (
          <>
            {isSubmitted() ? (
              <div className="icon-container">
                <CheckIcon color="success" />
              </div>
            ) : (
              <>
                {canSubmit ? (
                  <Button onClick={onClick} color={getButtonColor()} variant="outlined" size="small">
                    {t(getButtonText())}
                  </Button>
                ) : (
                  <EmptyCell />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
});

export default SubmittedCell;
