import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import MomentUtils from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { Loader } from 'components';
import ContentMultiple from 'components/ContentMultiple/ContentMultiple';
import InputDate from 'components/InputDate';
import AutocompleteCustom, { PropertyNameOptions, SelectTypeOptions } from 'components/Select';
import SelectUser from 'components/SelectUser';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
import moment, { Moment, MomentInput } from 'moment';
import * as momentTz from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDrawerAction } from 'store/actions/drawerActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { selectScopes } from 'store/selectors/deliverableSelectors';
import CatalogComplexity from 'types/entities/CatalogComplexity';
import MissionFrequency from 'types/entities/MissionFrequency';
import MissionStatus from 'types/entities/MissionStatus';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import Workunit from 'types/entities/Workunit';
import { DeliverableTabOption } from 'types/enums/DeliverableTabOption';
import { isActiveFrequency } from '../../services/deliverableService';

const DeliverableCreation: React.FC = () => {
  const userLocale = navigator.language || 'fr-FR';
  const dateFormat = userLocale !== 'en-US' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
  const guessedTimeZone = momentTz.tz.guess();
  momentTz.tz.setDefault(guessedTimeZone);
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const { createDeliverables } = useDeliverableService();
  const state = useSelector((state) => state.deliverable);
  const scopes = useSelector(selectScopes);
  const loadings = useSelector((state) => state.loadings);

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState<boolean>(true);
  const [deliverableToCreate, setDeliverableToCreate] = useState<Partial<Omit<OrderWorkunit, 'id'>>>({
    consultant: userRoles.isConsultant ? appState.user : null,
    client: null,
    order_id: state.order?.id,
  });
  const [currentTab, setCurrentTab] = useState<DeliverableTabOption>(DeliverableTabOption.CUSTOM);
  const [frequencyDate, setFrequencyDate] = useState<{
    start_date: string | null;
    end_date: string | null;
  }>({
    start_date: null,
    end_date: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAllowedToChangeUserInThisScope, setIsAllowedToChangeUserInThisScope] = useState(false);
  const [frequencySelected, setfrequencySelected] = useState(false);

  const orderScopes = state.order_scopes;

  useEffect(() => {
    const isFormCompleted = () => {
      if (
        currentTab === DeliverableTabOption.CATALOG &&
        !!(
          (deliverableToCreate?.workunit_reference && deliverableToCreate?.workunit_name) ||
          deliverableToCreate.workunit
        ) &&
        deliverableToCreate.client_id &&
        deliverableToCreate.scope_id &&
        (deliverableToCreate.forecast_date || (frequencyDate.end_date && frequencyDate.start_date))
      ) {
        return true;
      }
      if (
        currentTab === DeliverableTabOption.CUSTOM &&
        !!(deliverableToCreate?.workunit_reference && deliverableToCreate?.workunit_name) &&
        deliverableToCreate.client &&
        ((!frequencyWeekly() &&
          !frequencyMonthly() &&
          deliverableToCreate.forecast_date &&
          deliverableToCreate.scope_id &&
          deliverableToCreate.forecast_date !== null) ||
          ((frequencyWeekly() || frequencyMonthly()) && frequencyDate.end_date && frequencyDate.start_date))
      ) {
        return true;
      }
      return false;
    };
    if (isFormCompleted()) {
      setIsCreateButtonDisabled(false);
    } else if (!isFormCompleted()) setIsCreateButtonDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, deliverableToCreate, frequencyDate.end_date, frequencyDate.start_date]);
  useEffect(() => {
    if (userRoles.isConsultant) setIsAllowedToChangeUserInThisScope(false);
    const isDCButNotOnHisScope = !orderScopes?.find(
      (os) => os.scope_id === deliverableToCreate.scope_id && os.accountable_id === appState.user?.id
    );

    if (userRoles.isDeliveryCoordinator && isDCButNotOnHisScope) setIsAllowedToChangeUserInThisScope(false);
    else if (!userRoles.isConsultant) setIsAllowedToChangeUserInThisScope(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverableToCreate.scope_id]);

  const handleDateChange = (name: string, date: Moment | null) => {
    if (date)
      return setDeliverableToCreate({
        ...deliverableToCreate,
        [name]: moment(date)?.startOf('day').format('YYYY-MM-DD'),
      });
    return setDeliverableToCreate({ ...deliverableToCreate, [name]: null });
  };

  const handleSelectOptions = (e: SelectTypeOptions | null, property: PropertyNameOptions) => {
    if (e && property === 'scope')
      setDeliverableToCreate({ ...deliverableToCreate, scope: e as Scope, scope_id: e.id });
    if (!e && property === 'scope')
      setDeliverableToCreate({ ...deliverableToCreate, scope: undefined, scope_id: null });
    if (e && property === 'workunit') {
      setDeliverableToCreate({
        ...deliverableToCreate,
        workunit: e as Workunit,
        workunit_id: e.id,
        price: /* ((e as Workunit)?.complexity as CatalogComplexity)?.price ?? undefined */ undefined,
        workunit_complexity_id: /* ((e as Workunit)?.complexity as CatalogComplexity)?.id ?? undefined */ undefined,
        /* complexity: (e as Workunit)?.complexity as CatalogComplexity, */
        complexity_name: ((e as Workunit)?.complexity as CatalogComplexity).name,
      });
    }
    if (!e && property === 'workunit')
      setDeliverableToCreate({ ...deliverableToCreate, workunit: undefined, workunit_id: undefined, price: undefined });
  };

  const handleSelectConsultant = (consultant: User | null | undefined, reason: string | undefined) => {
    if (reason === 'clear') {
      resetSelectedConsultant();
    }
    if (consultant) {
      setDeliverableToCreate({ ...deliverableToCreate, consultant, consultant_id: consultant.id });
    }
  };

  const handleSelectDeliveryManager = (deliveryManager: User | null | undefined, reason: string | undefined) => {
    if (reason === 'clear') {
      resetSelectedDM();
    }
    if (deliveryManager) {
      setDeliverableToCreate({
        ...deliverableToCreate,
        delivery_manager: [deliveryManager],
        delivery_manager_ids: [deliveryManager.id],
      });
    }
  };

  const resetSelectedDM = () => {
    setDeliverableToCreate({ ...deliverableToCreate, delivery_manager: [], delivery_manager_ids: [] });
  };

  const resetSelectedConsultant = () => {
    setDeliverableToCreate({ ...deliverableToCreate, consultant_id: null, consultant: null });
  };

  // eslint-disable-next-line consistent-return
  const handleSelectClient = (client: User | null | undefined, reason: string | undefined) => {
    if (reason === 'clear') {
      resetSelectedClient();
    }
    if (client) {
      setDeliverableToCreate({ ...deliverableToCreate, client, client_id: client.id });
    }
  };

  const resetSelectedClient = () => {
    setDeliverableToCreate({ ...deliverableToCreate, client_id: null, client: null });
  };
  const { makeCall } = useApi();
  const submit = async (e?: { preventDefault: () => void }) => {
    e?.preventDefault();
    setIsLoading(true);
    setIsCreateButtonDisabled(true);
    await makeCall(
      createDeliverables(deliverableToCreate, isAllowedToChangeUserInThisScope, currentTab, frequencyDate),
      'Unable to create deliverable',
      'deliverableCreation'
    );

    closeDrawer();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, paramFilter?: MissionFrequency[] | MissionStatus[]) => {
    e.preventDefault();
    if (paramFilter)
      return setDeliverableToCreate({
        ...deliverableToCreate,
        [e.currentTarget.name]: paramFilter.filter((param) => Number(e.currentTarget.value) === param.id)[0],
      });
    return setDeliverableToCreate({ ...deliverableToCreate, [e.currentTarget.name]: e.currentTarget.value });
  };

  const closeDrawer = () => {
    dispatch(closeDrawerAction());
  };
  const frequencyWeekly = useCallback(
    () => (deliverableToCreate['mission-frequency'] as MissionFrequency | undefined)?.name === 'Weekly',
    [deliverableToCreate]
  );

  const frequencyMonthly = useCallback(
    () => (deliverableToCreate['mission-frequency'] as MissionFrequency | undefined)?.name === 'Monthly',
    [deliverableToCreate]
  );

  useEffect(() => {
    setfrequencySelected(frequencyMonthly() || frequencyWeekly());
  }, [deliverableToCreate, frequencyMonthly, frequencyWeekly]);
  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <form className="deliverable-creation-form" onSubmit={submit}>
        <Tabs
          value={currentTab}
          aria-label="disabled tabs example"
          onChange={(e, value) => setCurrentTab(value)}
          TabIndicatorProps={{ children: <span /> }}
          sx={{ mb: 4 }}
        >
          {state.workunits.length ? (
            <Tab label={DeliverableTabOption.CATALOG} value={DeliverableTabOption.CATALOG} />
          ) : (
            <Loader size={10} />
          )}
          <Tab label={DeliverableTabOption.CUSTOM} value={DeliverableTabOption.CUSTOM} />
        </Tabs>
        <>
          {currentTab === DeliverableTabOption.CUSTOM && (
            <>
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="workunit_reference">{t('Contract_reference')} *</InputLabel>
                <TextField
                  name="workunit_reference"
                  id="workunit_reference"
                  placeholder={t('Contract_reference')}
                  onChange={(e) => handleChange(e as any)}
                  value={deliverableToCreate?.workunit_reference ?? ''}
                  required
                  fullWidth
                  disabled={loadings.api > 0}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="workunit_name">{t('Deliverable_name')} *</InputLabel>
                <TextField
                  name="workunit_name"
                  id="workunit_name"
                  className="input-section"
                  placeholder={t('Deliverable_name')}
                  onChange={(e) => handleChange(e as any)}
                  value={deliverableToCreate?.workunit_name}
                  required
                  disabled={loadings.api > 0}
                  fullWidth
                />
              </Box>
            </>
          )}
          {currentTab === DeliverableTabOption.CATALOG && (
            <>
              <Box sx={{ mb: 3 }}>
                <AutocompleteCustom
                  label={t('Contract_reference')}
                  options={state.workunits}
                  optionsLabels="reference"
                  required
                  disabled={loadings.api > 0}
                  handleSelectOptions={handleSelectOptions}
                  selectedOption={deliverableToCreate?.workunit || null}
                  propertyName="workunit"
                />{' '}
              </Box>
              <Box sx={{ mb: 3 }}>
                <AutocompleteCustom
                  label={t('Deliverable_name')}
                  options={state.workunits}
                  optionsLabels="name"
                  required
                  handleSelectOptions={handleSelectOptions}
                  selectedOption={deliverableToCreate?.workunit || null}
                  propertyName="workunit"
                  disabled={loadings.api > 0}
                />{' '}
              </Box>
            </>
          )}
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="content">{t('Content')}</InputLabel>
            <TextField
              name="content"
              id="content"
              className="active-blue background-grey no-border deliverables-input "
              value={deliverableToCreate.content ?? ''}
              onChange={(e) => setDeliverableToCreate({ ...deliverableToCreate, [e.target.name]: e.target.value })}
              fullWidth
              disabled={loadings.api > 0}
              multiline
              rows={3}
            />
          </Box>

          <ContentMultiple orderWorkunit={deliverableToCreate as OrderWorkunit} onchange={setDeliverableToCreate} />

          <Box sx={{ mb: 3 }}>
            <AutocompleteCustom
              label={t('Scope')}
              options={scopes}
              optionsLabels="name"
              disabled={loadings.api > 0}
              required
              handleSelectOptions={handleSelectOptions}
              selectedOption={deliverableToCreate?.scope || null}
              propertyName="scope"
            />
          </Box>
          {deliverableToCreate?.scope && (
            <>
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="mission_frequency_id">{t('Frequency')} *</InputLabel>
                <RadioGroup
                  aria-label="mission_frequency_id"
                  name="mission-frequency"
                  id="mission_frequency_id"
                  row
                  onChange={(e) => handleChange(e, state.frequencies)}
                >
                  {state.frequencies.map((frequency) => (
                    <FormControlLabel
                      key={frequency.id}
                      value={frequency.id?.toString()}
                      className={`wrapped ${isActiveFrequency(deliverableToCreate, frequency)}`}
                      control={<Radio color="primary" />}
                      label={t(frequency.name) as string}
                      disabled={loadings.api > 0}
                    />
                  ))}
                </RadioGroup>
              </Box>
              {frequencySelected && (
                <Grid container spacing={4} sx={{ mb: 3 }}>
                  <Grid item xs={12} md={6}>
                    <InputDate
                      label={t('Start_frequency_date')}
                      name="start_date"
                      id="start_date"
                      placeholder={dateFormat}
                      onchange={(name, date) =>
                        setFrequencyDate({
                          ...frequencyDate,
                          [name]: moment(date as MomentInput)
                            .startOf('day')
                            .format('YYYY-MM-DD'),
                        })
                      }
                      value={frequencyDate.start_date || null}
                      required
                      min={moment(state.order?.start_date).toString()}
                      disabled={loadings.api > 0}
                      max={moment(state.order?.end_date).add('1', 'year').toString()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputDate
                      label={t('End_frequency_date')}
                      name="end_date"
                      id="end_date"
                      disabled={loadings.api > 0}
                      placeholder={dateFormat}
                      onchange={(name, date) =>
                        setFrequencyDate({
                          ...frequencyDate,
                          [name]: moment(date).startOf('day').format('YYYY-MM-DD'),
                        })
                      }
                      value={frequencyDate.end_date || null}
                      min={moment(state.order?.start_date).toString()}
                      required
                      max={moment(state.order?.end_date).add('1', 'year').toString()}
                    />
                  </Grid>
                </Grid>
              )}
              {!frequencySelected && (
                <Box sx={{ mb: 3 }}>
                  <InputDate
                    label={t('Date_delivery')}
                    name="forecast_date"
                    id="forecast_date"
                    placeholder={dateFormat}
                    onchange={handleDateChange}
                    value={deliverableToCreate.forecast_date || null}
                    min={moment(state.order?.start_date).toString()}
                    max={moment(state.order?.end_date).add('1', 'year').toString()}
                    required
                    disabled={loadings.api > 0}
                  />
                </Box>
              )}
              {state.consultants && !userRoles.isConsultant && (
                <Box sx={{ mb: 3 }}>
                  <SelectUser
                    users={state.consultants}
                    selectedUser={!isAllowedToChangeUserInThisScope ? appState.user : deliverableToCreate?.consultant}
                    onSelectUser={handleSelectConsultant}
                    label={t('Consultant')}
                    required
                    disabled={!isAllowedToChangeUserInThisScope || loadings.api > 0}
                  />
                </Box>
              )}
              {state.deliveryManagers?.length && (
                <Box sx={{ mb: 3 }}>
                  <SelectUser
                    users={state.deliveryManagers}
                    selectedUser={deliverableToCreate?.delivery_manager?.[0] || null}
                    onSelectUser={handleSelectDeliveryManager}
                    label={t('Delivery_manager')}
                    disabled={loadings.api > 0}
                  />
                </Box>
              )}
              {state.customers && (
                <Box sx={{ mb: 3 }}>
                  <SelectUser
                    users={state.customers}
                    selectedUser={deliverableToCreate?.client || null}
                    onSelectUser={handleSelectClient}
                    label={t('Client')}
                    required
                    disabled={loadings.api > 0}
                  />
                </Box>
              )}
            </>
          )}
        </>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingButton
            loading={loadings.api > 0}
            disabled={isCreateButtonDisabled || !!loadings.api}
            onClick={submit}
            startIcon={<Add />}
            variant="contained"
            color="success"
          >
            {t('Add_this_new_deliverable')}
          </LoadingButton>
        </Box>
      </form>
    </LocalizationProvider>
  );
};

export default DeliverableCreation;
